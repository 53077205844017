//
// _team.scss
//

.team-box .team-cover,
.profile-offcanvas .team-cover,
.modal-team-cover {
  display: none;
  position: relative;
  margin-bottom: -140px;

  img {
    height: 140px;
    width: 100%;
    object-fit: cover;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, darken($primary, 25%), $primary);
    opacity: 0.6;
  }
}

.team-list {
  &.grid-view-filter {
    flex-flow: row wrap;
    .card-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
    }
    .team-box {
      overflow: hidden;

      .team-row {
        align-items: start;

        .col {
          width: 100%;
        }
      }

      .team-cover {
        display: block;
      }

      .team-settings {
        position: absolute;
        right: 10px;
        top: 20px;
        z-index: 2;
        width: 30px;
        height: 30px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        i,
        svg {
          font-size: 24px;
          path {
            fill: #757575;
          }
        }
        .col {
          width: 50% !important;
          flex: 0 0 auto;
        }

        .btn-star {
          color: $white;
        }

        .dropdown {
          > a {
            color: $white;
          }
        }
      }

      .team-profile-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 25px;
        margin-top: 36px;

        .avatar-lg {
          font-size: 22px;
        }

        .team-content {
          margin-left: 0px;
          margin-top: 25px;
          text-align: center;
        }
      }
      .dropdown-item {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }

  &.list-view-filter {
    flex-direction: column;

    .team-box {
      .card-body {
        padding: 30px;
      }
      .team-row {
        align-items: center;
        justify-content: space-between;
      }

      .team-profile-img {
        display: flex;
        align-items: center;
        flex-direction: column;
        .profile-main-box {
          position: relative;
          width: 125px;
          height: 125px;
          border-radius: 50%;
          overflow: hidden;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #fff;
          // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          .profile-box {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #fff;
            // box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          .progress-ring {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            border: 5px solid #f3f3f3;
            border-top-color: #ffa500;
            // animation: rotate 2s linear infinite;
            transform: rotateZ(45deg);
          }
        }

        // .avatar {
        //   width: 60px;
        //   height: 60px;
        //   border-radius: 50%;
        // }
        .profile-image {
          width: 100px !important;
          height: 100px !important;
          font-size: 20px !important;
        }
        // .avatar,
        // .profile-image {
        //   // margin-bottom: 10px;
        //   border: 1px solid #e4e8eb;
        // }
        .avatar-lg {
          height: 4rem;
          width: 4rem;
          font-size: 16px;
        }

        .team-content {
          // margin-left: 15px;
          text-align: center;
          width: 100%;
          margin-top: 15px;
          // display: flex;
          // align-items: end;
          // justify-content: space-between;
        }
        .name {
          font-size: 24px;
          margin-bottom: 3px;
          text-transform: capitalize;
        }
        .designation {
          font-size: 16px;
          text-transform: capitalize;
        }
        
      }
      .project {
        font-size: 18px;
        font-weight: 500;
        border: 1px solid #e4e8eb;
        border-radius: 8px;
        padding: 5px;
        margin-top: 30px;
        width: 100%;
        text-align: center;
      }
      .team-settings {
        width: auto;
        flex: 0 0 auto;
        -webkit-box-ordinal-group: 7;
        order: 6;
        position: absolute;
        right: -5px;
        top: 0;
        z-index: 2;
        svg path {
          fill: #757575;
        }
      }
      .dropdown-menu {
        .dropdown-item {
          display: flex;
          align-items: center;
          cursor: pointer;
          svg {
            font-size: 17px;
            margin-right: 10px;
            path {
              fill: #4c525e;
            }
          }
        }
      }
      .btn-star {
        color: $primary;
      }
    }

    @media (max-width: 767.98px) {
      flex-direction: row;
      .col {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: 100%;
      }
      .team-box {
        .team-settings {
          // width: 100%;
          flex: 0 0 auto;
          order: -1;
          margin-bottom: 10px;

          .col {
            width: 50%;
          }
        }

        .team-profile-img {
          margin-bottom: 25px;
        }

        .view-btn {
          width: 100%;
          margin-top: 25px;
        }
      }
    }
  }
}

.modal-team-cover {
  display: block;
}

.list-grid-nav {
  .nav-link {
    &.active {
      background-color: $info;
      color: $white;
    }
  }
}

.profile-offcanvas {
  .team-cover {
    margin-bottom: -132px;
    display: block;
    z-index: -1;
  }
  .btn-star {
    color: $white;
  }

  .dropdown {
    > a {
      color: $white;
    }
  }
}
