.comment-page {
  .profile {
    position: relative;
    .profile-img {
      position: relative;
      z-index: 1;
      background: #e4eaee;
      border-radius: 50px;
      width: 40px;
      height: 40px;
    }
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 100%;
      border-left: 2px dashed #e4e8eb;
      // background: #c7c7c7;
      left: 0;
      right: 0;
      margin: 0 auto;
      bottom: 0;
    }
  }
  // Search
  .search-box {
    position: relative;
    .form-control {
      padding: 10px 10px 10px 40px;
      width: 300px;
      border-radius: 8px;
      background-color: #fff;
      border: 0;
    }
    .search-icon {
      font-size: 16px;
      position: absolute;
      left: 13px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      color: rgb(186, 188, 193);
    }
  }
  .send-btn {
    display: flex;
    align-items: center;
    gap: 5px;
    svg {
      font-size: 15px;
    }
  }
}
