.global-not-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e4e8eb;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
  }
}

.customize-not-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
  gap: 15px;
  @media (max-width: 767.98px) {
    flex-direction: column;
    align-items: start;
  }
  .title {
    margin-bottom: 0;
    font-size: 16px;
    width: 35%;
    font-weight: 500;
    @media (max-width: 767.98px) {
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
    }
  }
  .right-side {
    display: flex;
    align-items: center;
    gap: 10px;
    @media (max-width: 767.98px) {
      flex-direction: column;
      align-items: start;
      width: 100%;
    }
  }
  @media (max-width: 767.98px) {
    .MuiFormControl-root {
      width: calc(100% - 16px);
      margin-left: 16px;
    }
  }
}
