.package-card { 
    position: relative;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0 10px 20px rgba(0,0,0,0.03);
    border-radius: 16px;
    height: 100%;
    transition: all 0.4s ease-in-out;
    overflow: hidden;
    &::before {
        position: absolute;
        content: "";
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 70px 70px 0 0;
        border-color: #1A2232 transparent transparent transparent;
    }
    &::after{
        position: absolute;
        content: "";
        width: 8px;
        height: 8px;
        background: #ffffff;
        border-radius: 10px;
        left: 17px;
        top: 16px;
    }
    &:hover {
        box-shadow: 0 15px 30px rgba(0,0,0,0.15);
    }
    .heading {
        padding: 30px 50px 25px 50px;
        .package-title {
            font-size: 20px;
            padding-bottom: 15px;
            @media (max-width: 991px) {
                padding-bottom: 10px;
            }
        }
        .price-txt {
            position: relative;
            font-size: 40px;
            line-height: 100%;
            font-weight: bold;
            color: #f99f1e;
            @media (max-width: 991px) {
                font-size: 30px;
            }
            .old-price {
                opacity: 0.3;
                font-weight: 500;
                display: inline-block;
                color: #666666;
                font-size: 60%;
                &:before {
                    content: "/";
                    margin: 0 0 0 8px;
                }
            }    
            small {
                font-weight: normal;
                font-size: 40%;
            }
            .line-through {
                text-decoration: line-through;
            }
        }
    }
    .package-footer {
        padding: 30px;
        .btn:disabled, .btn.disabled {
            opacity: 0.2;
        }
        @media (max-width: 991px) {
            padding: 20px;
        }
    }
    .ul-list {
        margin: 0;
        padding: 0 30px;
        @media (max-width: 991px) {
            padding: 0 20px;
        }
        li {
            list-style: none;
            margin: 0;
            padding: 0;
            @media (max-width: 991px) {
                font-size: 14px;
            }
            + li {
                border-top: 1px solid rgba(0, 0, 0, 0.05);
                margin-top: 7px;
                padding-top: 7px;
            }
            .check-sign, .cross-sign {
                width: 24px;
                height: 24px;
                @media (max-width: 991px) {
                    width: 20px;
                    height: 20px;
                }
            }
            .check-sign {
                fill: #52c648;
            }
            .cross-sign {
                fill: #d32f2f;
            }
            p {
                @media (max-width: 991px) {
                    font-size: 14px;
                }
            }
        }
    }
}
.package-card.selected {
    border: 2px solid #111218; /* Example: blue border for selected plan */
    background-color: #f0f0f0; /* Light background color */
  }
  