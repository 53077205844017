//
// _dashboard.scss
//

.topcard-grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 24px;
  overflow: hidden;
  .item {
    // background-color: rgba(155, 155, 155, 0.8);
    // text-align: center;
    // padding: 20px 0;
    // font-size: 30px;
    
    width: 296px;
    &.send-item {
      @media screen and (min-width: 1899.98px) {
        width: 200px;
      }
    }
    &:empty {
      display: none;
    }
  }

  @media screen and (max-width: 767.98px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media screen and (max-width: 1899.98px) {
    .item {
      width: 100%;
    }
  }
  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media screen and (min-width: 1600px) {
    grid-template-columns: repeat(5, 1fr);
  }
}

.dashboard-page {
  .page-title {
    font-weight: bold;
  }
}
.dashboard-top-card {
  border-radius: 16px;
  border: 0;
  position: relative;
  margin-bottom: 0;
  .card-body {
    padding: 14px;
  }
  .body-top {
    display: flex;
    @media screen and (min-width: 1600px) and (max-width: 1900px) {
      flex-direction: column;
    }
  }
  .body-right {
    margin-left: 50px;
    @media screen and (min-width: 1600px) and (max-width: 1900px) {
      margin-left: 0px;
      margin-top: 10px;
    }
    @media (max-width: 1599px){
      margin-left: 20px;
    }
  }
  .avatar {
    width: 60px;
    height: 60px;
    .avatar-title {
      border-radius: 13px;
      background-color: rgba(255, 255, 255, 0.2);
    }
    svg {
      width: 30px;
      height: 30px;
    }
  }
  .title {
    font-weight: 500;
    color: #fff;
    @media screen and (min-width: 1600px) and (max-width: 1900px) {
      margin-bottom: 2px;
    }
  }
  .subtitle {
    font-weight: 500;
    color: #fff;
    font-size: 18px;
    word-break: break-word;
  }
  .link-box {
    margin-left: 20px;
    @media screen and (min-width: 1600px) and (max-width: 1900px) {
      margin-left: 0;
    }
  }
  .icon-button {
    padding: 3px;
    border-radius: 7px 50% 50%;
    position: absolute;
    right: 0;
    bottom: 0;
    svg {
      fill: $primary;
    }
  }
  .badge {
    font-size: 14px;
    border-radius: 20px;
    color: $primary;
    font-weight: 600;
    background-color: #fff;
    padding: 8px 14px;
    i,
    svg {
      margin-right: 5px;
    }
    @media screen and (min-width: 1600px) and (max-width: 1900px) {
      display: block;
      width: fit-content;
    }
  }
  .hits-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 17px;
    padding: 0px 15px 0 12px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #d4d9de #f5ad3e;
    gap: 10px;
    .title,
    .value {
      color: #fff;
      font-weight: 500;
    }
    .title {
      font-size: 16px;
    }
    @media screen and (min-width: 1600px) and (max-width: 1900px) {
      flex-direction: column;
      padding: 0;
      align-items: start;
    }
  }
}
// .grid-container {
//   display: grid;
//   grid-template-columns: repeat(5, 1fr);
//   grid-gap: 24px;
//   overflow: hidden;
//   .item {
//     background-color: rgba(155, 155, 155, 0.8);
//     text-align: center;
//     padding: 20px 0;
//     font-size: 30px;
//     width: 296px;
//     &:last-child {
//       width: 200px;
//     }
//   }
//   @media (max-width: 767.98px) {
//     grid-template-columns: repeat(1, 1fr);
//     .item {
//       width: 100%;
//     }
//   }
//   @media (min-width: 768px) and (max-width: 991.98px) {
//     grid-template-columns: repeat(2, 1fr);
//   }
//   @media (min-width: 1200px) and (max-width: 1399.98px) {
//     grid-template-columns: repeat(3, 1fr);
//   }
// }
.dash-filter-picker {
  min-width: 210px !important;
}
.site-table-container {
  border-radius: 16px;
  overflow: hidden;
  .card-header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767.98px) {
      flex-direction: column;
      .view-btn {
        border-radius: 8px !important;
      }
    }
    @media (min-width: 768px) {
      flex-wrap: wrap;
    }
    @media (max-width: 1599.98px) {
      .online-btn {
        border-radius: 8px !important;
      }
    }
  }
  .dash_tbl_filter {
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    .nav-item {
      @media (max-width: 767px) {
        flex: 1 0 auto;
      }
      &:nth-of-type(1) {
        .nav-link {
          margin-left: 0;
          border-radius: 0;
        }
      }
      &:nth-of-type(2) {
        .nav-link {
          background-color: #e3eaee;
        }
      }
      &:nth-of-type(3) {
        .nav-link {
          background-color: #dae0e3;
        }
      }
    }
    .nav-link {
      background-color: #f3f6f9;
      font-size: 18px;
      border-radius: 0 16px 0 16px;
      padding: 8px 60px 8px 20px;
      margin-left: -12px;
      color: $primary;
      align-items: center;
      &.active {
        background-color: #fff !important;
      }
      @media (max-width: 767px) {
        margin-left: 0;
      }
    }
  }
  .site-table-headleft {
    width: calc(100% - 130px);
    overflow-x: auto;
    scrollbar-width: thin;
  }
  .search-box {
    position: relative;
    .form-control {
      padding: 10px 10px 10px 40px;
      width: 300px;
      border-radius: 8px;
      background-color: #f5f7f8;
      border: 0;
      height: 40px;
      @media screen and (max-width: 1700px) {
        width: 200px;
      }
    }
    .search-icon {
      font-size: 16px;
      position: absolute;
      left: 13px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      color: rgb(186, 188, 193);
    }
  }
  .dateinput {
    .form-control {
      width: 300px;
      border-radius: 8px;
      background-color: #f5f7f8;
      border: 0;
      height: 40px;
      @media screen and (max-width: 1600px) {
        width: 200px;
      }
    }
  }
  .view-btn {
    min-width: 122px !important;
    border-radius: 0 16px 0 16px !important;
  }
}
.table-main-card {
  .table-card-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0.5rem;
    @media (max-width: 767.98px) {
      flex-direction: column-reverse;
      align-items: end;
    }
    .left-side {
      display: flex;
      gap: 0.5rem;
      overflow: auto;
      padding: 1rem 1.5rem;
      scrollbar-width: thin;
      @media (max-width: 767.98px) {
        width: 100%;
        padding: 1rem;
      }
    }
    .view-btn {
      border-radius: 0 16px 0 16px !important;
    }
  }
  .search-box {
    position: relative;
    .form-control {
      padding: 10px 10px 10px 40px;
      width: 250px;
      border-radius: 8px;
      background-color: #f5f7f8;
      border: 0;
      @media (max-width: 1400px) {
        width: 200px;
      }
    }
    .search-icon {
      font-size: 16px;
      position: absolute;
      left: 13px;
      top: 0;
      height: 100%;
      display: flex;
      align-items: center;
      color: rgb(186, 188, 193);
    }
  }
  .dateinput {
    .form-control {
      width: 250px;
      border-radius: 8px;
      background-color: #f5f7f8;
      border: 0;
      height: 40px;
      @media (max-width: 1400px) {
        width: 200px;
      }
    }
  }
  .card-body {
    padding: 1.5rem;
  }
}
.reset-btn {
  padding: 0 8px !important;
  height: 40px;
  min-width: 40px !important;
}
.table-design {
  .rdt_Table,
  table {
    scrollbar-width: thin !important;
    .rdt_TableHeadRow,
    thead tr {
      border: 0;
      font-weight: 400;
      font-size: 16px;
      .rdt_TableCol,
      th {
        color: #757a84;
        border: 0;
      }
    }
    .rdt_TableCol {
      background: transparent;
    }
    .rdt_TableBody,
    tbody {
      .rdt_TableRow,
      tr {
        font-size: 14px;
        font-weight: 500;
        color: $primary;
        border: 0;
        &:nth-of-type(odd) {
          background: #f5f7f8;
          .MuiInputBase-root {
            background: #fff;
          }
        }
        &:nth-of-type(even) {
          background: #ffffff;
        }
        td {
          border: 0;
        }
        svg {
          path {
            fill: #4c525e;
          }
        }
      }
      .badge {
        font-size: 14px;
        font-weight: 500;
        color: $primary;
        text-transform: capitalize;
        border-radius: 20px;
        padding: 5px 10px;
      }
    }
    .table-action {
      display: flex;
      gap: 10px;
      svg {
        path {
          fill: #4c525e;
        }
      }
    }
  }
}
.rdt_Pagination {
  border-top: 0 !important;
  font-size: 16px !important;
  color: $primary !important;
}
// Flatpickr Calendar$heading-color

.upcoming-scheduled {
  position: relative;
  @media (max-width: 575.98px) {
    top: 35px !important;
  }
  .flatpickr-months {
    position: absolute !important;
    top: -45px !important;
    left: auto !important;
    right: 0px !important;
    width: 200px;
    background-color: transparent;

    .flatpickr-month {
      color: $gray-600 !important;
      fill: $gray-600 !important;
    }
    .flatpickr-prev-month,
    .flatpickr-next-month {
      display: none;
    }
  }
  .flatpickr-calendar {
    box-shadow: none !important;
    .flatpickr-current-month {
      font-size: 13px;
      width: 100%;
      left: 0;
    }
    .flatpickr-monthDropdown-months {
      border: 1px solid $border-color;
      border-radius: 4px;
      height: 26px;
    }
    .flatpickr-weekdays {
      background-color: var(--#{$prefix}light);
      border: none;
      span.flatpickr-weekday {
        color: var(--#{$prefix}dark);
        background-color: var(--#{$prefix}light);
      }
    }
    .flatpickr-day {
      &.today {
        color: $white !important;
        background-color: $success;
        border-color: $success !important;
        &:hover {
          color: $success !important;
          background-color: rgba($success, 0.2) !important;
        }
      }
      &.selected {
        background-color: $success !important;
        border-color: $success !important;
        color: $white;
      }
    }
    .numInputWrapper {
      width: 7.5ch;
      margin-left: 10px;
    }
  }

  .flatpickr-days {
    border: none !important;
  }
}

.crm-widget {
  .col {
    border-right: 1px solid $border-color;
    &:last-child {
      border: 0px;
    }
    @media (min-width: 768px) and (max-width: 1399.98px) {
      &:nth-of-type(3) {
        border-right: 0px;
      }
      &:last-child {
        border-right: 1px solid $border-color;
      }
    }
    @media (max-width: 767.98px) {
      border-right: 0px;
      border-bottom: 1px solid $border-color;
    }
  }
}

@media (min-width: 1400px) and (max-width: 1599.98px) {
  .project-wrapper {
    > .col-xxl-8,
    .col-xxl-4 {
      width: 100%;
    }
  }
}

.crypto-widget {
  max-width: 130px !important;
}

//nft dashboard

.bg-marketplace {
  background-image: url(../../images/nft/marketplace.png);
  background-size: cover;
}

.dash-countdown {
  .countdownlist {
    .count-num {
      background-color: var(--#{$prefix}card-bg-custom);
      padding: 16px 8px;
      font-size: 22px;

      @media (max-width: 575.98px) {
        font-size: 16px;
        padding: 8px 6px;
      }
    }

    .count-title {
      @media (max-width: 575.98px) {
        font-size: 10px;
      }
    }
  }
}

.marketplace-icon {
  position: absolute;
  float: right;
  top: 30px;
  left: 30px;
}

.marketplace-swiper {
  .swiper-button-next,
  .swiper-button-prev {
    top: 34px;
    width: 28px;
    height: 28px;
    background-color: rgba($primary, 0.1);
    color: $primary;
    border-radius: 0.3rem;
    right: 16px !important;
  }
  .swiper-button-prev {
    right: 58px !important;
    left: auto !important;
  }
}

.dash-collection {
  .content {
    background-color: rgba($white, 0.25);
    backdrop-filter: blur(5px);
  }
}

.dash-nft {
  @media (max-width: 1441.98px) {
    .col-xxl-9,
    .col-xxl-3 {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}

.jvectormap-legend-cnt-h .jvectormap-legend-tick-sample {
  width: 32px;
  height: 32px;
  display: inline-block;
  vertical-align: middle;
}

// 08-01-2024 changes

.priority_select {
  min-width: 120px;
}
.website-up-down {
  min-width: 55px;
  text-align: center;
}
.pagespeed {
  min-width: 75px;
  text-align: center;
  justify-content: center;
}
.traffic {
  min-width: 60px;
  text-align: center;
}

// Online Assets Css Start

.assets-top-details {
  align-items: center;
  margin-bottom: 20px;
  .web-link {
    font-size: 22px;
    font-weight: bold;
    word-break: break-all;
    @media screen and (max-width: 767.98px) {
      font-size: 18px;
    }
  }
  @media screen and (min-width: 1400px) {
    .web-link {
      padding-left: 50px;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: 50px;
        background: #ced4da;
      }
    }
  }
  .left-side {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    gap: 20px;
    @media screen and (max-width: 375px) {
      flex-direction: column;
      align-items: start;
    }
    .company-name {
      font-size: 24px;
      font-weight: bold;
      @media screen and (max-width: 767.98px) {
        font-size: 20px;
      }
    }
  }
  .company-info {
    background-color: #e3eaee;
    color: $primary;
    border-radius: 8px;
    padding: 20px;
    position: relative;
    font-size: 14px;
    .edit-button {
      position: absolute;
      top: 0;
      right: 0;
      background-color: #ced4da;
      padding: 0 5px 5px 8px;
      border-radius: 0 8px;
      min-width: auto;
      svg {
        width: 17px;
        path {
          fill: #757a84;
        }
      }
    }
  }
}
.assets-tabs {
  .tabpanel {
    padding: 0;
    margin: 24px 0;
  }
}
.statistics-tabpanel {
  .main-card {
    border-radius: 16px;
    overflow: hidden;
    height: 100%;
    border: 0;
    // padding: 10px;
    color: $primary;
    .card-header {
      border-bottom: 0;
    }
    .card-title {
      font-size: 18px;
      color: $primary;
      font-weight: bold;
      margin-bottom: 0;
    }
    hr {
      border-color: #e4e8eb;
    }
  }
}
.pagespeed-card {
  border-radius: 8px;
  padding: 15px 10px;
  color: $primary;
  text-align: center;
  width: 33%;

  svg,
  i {
    margin-bottom: 20px;
  }
  .time {
    font-weight: 600;
    font-size: 18px;
  }
  .title {
    font-size: 12px;
    margin-bottom: 0;
  }
  &:nth-of-type(1) {
    background-color: #ebc6d6;
  }
  &:nth-of-type(2) {
    background-color: #fde6c5;
  }
  &:nth-of-type(3) {
    background-color: #d4dfef;
  }
  .MuiAvatar-root {
    width: 80px;
    height: 80px;
    font-size: 30px;
    font-weight: 500;
    background-color: #fff;
    color: $primary;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
}
.analytics-bar {
  color: $primary;
  border-bottom: 1px solid #e4e8eb;
  padding: 17px 0;
  &:last-child {
    border-bottom: 0;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
  }
  .subtitle {
    font-size: 14px;
    color: #757a84;
  }
  .value {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 0;
    text-align: end;
    @media (max-width: 767.98px) {
      font-size: 20px;
      text-align: left;
      padding-left: 57px;
    }
  }
  .avatar {
    margin-right: 16px;
  }
  svg {
    width: 18px;
    path,
    clippath {
      fill: $primary;
    }
  }
  img {
    width: 40px;
    height: 40px;
  }
  @media screen and (min-width: 1400px) and (max-width: 1600px) {
    .title {
      font-size: 14px;
    }
    .subtitle {
      font-size: 12px;
    }
    .value {
      font-size: 18px;
    }
    .avatar {
      margin-right: 10px;
    }
  }
}
.metric-card {
  .btn-primary {
    padding: 7px 18px;
  }
  .btn-sm {
    padding: 7px 10px;
    margin-left: 6px;
    background-color: #f1f3f6 !important;
    color: $primary !important;
    font-size: 14px;
  }
}
.search-box {
  position: relative;
  .form-control {
    padding: 10px 10px 10px 40px;
    width: 300px;
    border-radius: 8px;
    background-color: #f5f7f8;
    border: 0;
    @media (max-width: 1400px) {
      width: 200px;
    }
  }
  .search-icon {
    font-size: 16px;
    position: absolute;
    left: 13px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    color: rgb(186, 188, 193);
  }
}
.date-box {
  border-radius: 8px;
  background-color: #f5f7f8 !important;
  border: 0;
  height: 40px;
}
