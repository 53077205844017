//
// _profile.scss
//
.developerProfile-page {
  .profile-header {
    .search-box {
      .form-control {
        background-color: #fff;
        width: 400px;
        @media (max-width: 1400px) {
          width: 300px;
        }
      }
    }
  }
}
.developerprofile-details {
  .custom-card {
    border-radius: 16px;
    border: 0;
    background-color: #fff;
    // box-shadow: 0 10px 30px 0 var(--primary005);
    position: relative;
    margin-block-end: 1.25rem;
    width: 100%;
    .profile-cover__img {
      position: absolute;
      inset-inline-start: 50px;
      inset-block-start: 170px;
      text-align: center;
      z-index: 1;
      @media (max-width: 767.98px) {
        inset-inline: 0 !important;
        inset-block-start: 170px;
      }
      .avatar-img {
        max-width: 120px;
        border: 5px solid #fff;
        min-width: 120px;
        min-height: 120px;
        background: #f1f1f1;
        border-radius: 50%;
      }
      .h3 {
        margin-block-start: 6px;
      }
    }
    .profile-cover_action {
      display: flex;
      // padding-block-start: 216px;
      // padding-inline-end: 30px;
      // padding-block-end: 10px;
      // padding-inline-start: 185px;
      border-radius: 8px;
      // flex-wrap: wrap;
      // justify-content: flex-end;
      overflow: hidden;
      height: 226px;
      max-height: 226px;
      // background: url(../../images/profile-bg.jpg) no-repeat;
      // background-size: cover;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .edit-icon {
        width: 30px;
        height: 30px;
        background: #9ea3ab;
        border: 2px solid #fff;
        padding: 6px;
        border-radius: 50%;
        position: absolute;
        bottom: 10px;
        right: 10px;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
    .profile-cover__info .nav {
      @media (min-width: 601px) {
        margin-inline-end: 28px;
        padding-block-start: 15px;
        padding-inline-end: 0;
        padding-block-end: 10px;
        padding-inline-start: 170px;
        color: #999;
        font-size: 16px;
        line-height: 26px;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
        justify-content: flex-end;
      }
      @media (max-width: 767.98px) {
        display: flex;
        margin-inline-end: 0;
        padding-inline: 0 !important;
        padding-block-start: 100px !important;
        padding-block-end: 10px !important;
        color: #999;
        font-size: 16px;
        line-height: 26px;
        font-weight: 300;
        text-align: center;
        text-transform: uppercase;
        justify-content: center;
      }
      li {
        margin-block-start: 13px;
        margin-block-end: 13px;
        font-size: 14px;
        color: $primary;
        font-weight: 500;
        @media (max-width: 767.98px) {
          margin-block-start: 27px;
          margin-block-end: 0;
          margin-inline-end: 2rem;
          font-size: 13px;
        }
        strong {
          display: block;
          margin-block-end: 10px;
          font-size: 29px;
        }
        &:not(:first-child) {
          margin-inline-start: 30px;
          padding-inline-start: 30px;
          border-inline-start: 1px solid #e4e8eb;
          @media (max-width: 767.98px) {
            margin-block-start: 8px;
            margin-inline-start: 0;
            padding-block-start: 18px;
            padding-inline-start: 0;
            border-top: 0;
            border-inline-start-width: 0;
          }
        }
      }
    }
  }
  .headeing {
    text-align: start;
    line-height: 2.5;
    color: $primary;
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 20px;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 1px;
      background: #e4e8eb;
    }
    &::after {
      content: "";
      position: absolute;
      width: 10%;
      height: 3px;
      left: 0;
      bottom: 0;
      background: #f99f1e;
      border-radius: 30px;
    }
  }
  .skill-box {
    .MuiChip-root {
      margin: 4px 4px 4px 0;
      background: #ced4da;
      color: #1a2232;
    }
  }
  .profile-project-card {
    border: 1px solid #e4eaee;
    border-left: 6px solid #e4eaee;
  }
}
.divider {
  border-color: #c1c1c1 !important;
  margin: 30px 0 !important;
}
// .error-text {
//   text-shadow: 4px 4px rgba($success, 0.4);

//   @media (min-width: 1200px) {
//     font-size: 10rem;
//   }
// }

// profile

.profile-wid-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 320px;
  border-radius: 16px;
  overflow: hidden;
  @media (max-width: 575.98px) {
    height: 445px;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.9;
    background: $primary;
    background: linear-gradient(to top, darken($primary, 25%), $primary);
  }

  .profile-wid-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.profile-nav {
  &.nav-pills {
    .nav-link {
      color: rgba($white, 0.8);

      &::before {
        background-color: rgba($white, 0.1);
      }
    }
  }
}

.profile-project-card {
  border: 1px solid #e4eaee;
  border-left: 3px solid #e4eaee;
}

@each $color, $value in $theme-colors {
  .profile-project-card {
    &.profile-project-#{$color} {
      border-left-color: $value;
    }
  }
}

.user-profile-img {
  position: relative;

  .profile-img {
    width: 100%;
    height: 250px;
    object-fit: cover;

    @media (max-width: 991px) {
      height: 160px;
    }
  }

  .profile-foreground-img-file-input {
    display: none;
  }

  .profile-photo-edit {
    cursor: pointer;
  }
}

.profile-user {
  position: relative;
  display: inline-block;
  border-radius: 8px;
  overflow: hidden;
  // width: 100%;
  // height: 10rem;
  // border: 1px solid #ddd;
  .profile-photo-edit {
    position: absolute;
    right: 0;
    left: auto;
    bottom: 0;
    cursor: pointer;
    width: 40px;
    height: 40px;
  }

  .user-profile-image {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .profile-img-file-input {
    display: none;
  }
}

.profile-timeline {
  .accordion-item {
    position: relative;
    background: #fff;
    border: 0;
    margin-bottom: 10px;
    border-radius: 16px;
    padding: 1rem;

    .accordion-button {
      background-color: transparent;
      .title {
        font-size: 16px;
        margin-bottom: 7px;
        color: $primary;
      }
      .sub-box {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 5px;
        @media (max-width: 767.98px) {
          flex-direction: column-reverse;
          align-items: start;
        }
      }
      .activity {
        font-size: 14px;
        color: #757a84;
      }
      &::after {
        background: none;
      }
      .timing {
        margin-bottom: 0;
        gap: 10px;
        margin-left: auto;
        display: flex;
        @media (max-width: 767.98px) {
          margin-left: unset;
        }
        small {
          font-size: 12px;
        }
      }
    }

    &::before {
      content: "";
      border-left: 2px dashed #e4e8eb;
      position: absolute;
      height: 100%;
      left: 23px;
      top: 0;
    }

    // &:first-child {
    //   &::before {
    //     top: 8px;
    //   }
    // }

    // &:last-child {
    //   &::before {
    //     height: 20px;
    //     top: 3px;
    //   }
    // }
  }
}
.profile-setting-img {
  .overlay-content {
    // content: "";
    // position: absolute;
    // top: 0;
    // right: 0;
    display: flex;
    text-align: end;
    flex-direction: column;
    align-items: flex-end;
  }
  .profile-wid-img {
    height: 250px;
    background: #f3f3f3;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .profile-img {
    width: 100%;
    height: 250px;
    object-fit: cover;

    @media (max-width: 991.98px) {
      height: 160px;
    }
  }

  .profile-foreground-img-file-input {
    display: none;
  }

  .profile-photo-edit {
    cursor: pointer;
  }
}

@media (max-width: 575.98px) {
  [data-layout="horizontal"] {
    .profile-foreground {
      margin-top: 0 !important;
    }
  }
}

.myprofile-page {
  .profile-cover {
    padding-bottom: 100px;
    .profile-cover__img {
      left: 0;
      right: 0;
    }
  }
}
