//
// _topbar.scss
//

#page-topbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1002;
  background-color: transparent;
  // transition: 0.2s;

  &.topbar-shadow {
    box-shadow: 0 0 12px 0px rgba(56, 65, 74, 0.1);
    background: #f5f7f8;
  }

  @media (min-width: 992px) {
    left: 250px;
  }
  @media (min-width: 1200px) {
    left: $vertical-menu-width;
  }
}

.navbar-header {
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  height: $header-height;
  // overflow: hidden;
  padding: 0 0 0 calc(#{$grid-gutter-width} / 2)
    /*rtl: 0 0 0 calc(#{$grid-gutter-width} / 2) */;

  @media (max-width: 767.98px) {
    padding: 0;
  }
  .title_breadcum {
    width: auto;
    min-width: 180px;
    @media (max-width: 767px) {
      width: 100%;
      min-width: auto;
    }
  }
  .login-uname {
    font-size: 18px;
    color: #1A2232;
    text-transform: capitalize;
    max-width: 240px;
    @media (max-width: 1599px) {
      font-size: 16px;
    }
    @media (max-width: 1279px) {
      font-size: 14px;
      max-width: 100px;
    }
  }
  .topbar-head-dropdown {
    .btn {
      @media (max-width: 767px) {
        padding: 0px 3px !important;
        width: auto;
      }
    }
  }
  .header-search {
    position: relative;
    .header-search-visible {
      position: absolute;
      left: 0;
      top: 40px;
      max-width: 455px;
      width: 100%;
      border: 1px solid #e4e8eb;
      background: #ffffff;
      margin: 0;
      padding: 0;
      list-style: none;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 1000;
      @media (max-width: 767px) {
        border: 0;
        max-height: 50vh;
      }
      li {
        &:hover {
          background: #f5f5f5;
        }
      }
    } 
  }
  .topbar-head-dropdown {
    .dropdown-menu.show {
      top: 13px !important;
    }
  }
  .setting-btn {
    background: #e4e8eb;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-topbar {
    height: 42px;
    width: 42px;

    @media (max-width: 360px) {
      height: 36px;
      width: 36px;
    }
  }

  .user-name-text {
    color: var(--#{$prefix}gray-700);
  }
  .dropdown-menu {
    .tab-content {
      .tab-pane {
        background-color: #fff;
      }
    }
    .nav-tabs-custom {
      .nav-item {
        .nav-link {
          &::after {
            content: none;
          }
        }
      }
    }
    .setting-btn {
      background-color: #e4e8eb;
      color: $primary;
      padding: 20px;
    }
  }
}

/* Search */
.mobile-show-pagetitle {
  .title_breadcum {
    margin: 0;
    width: 100%;
    padding: 0 0 25px 0;
    font-size: 24px;
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
}
.app-search {
  padding: calc(#{$header-height - 38px} / 2) 0;

  .form-control {
    border: none;
    height: 38px;
    padding-left: 40px;
    padding-right: 30px;
    background-color: $topbar-search-bg;
    box-shadow: none;
  }

  span.search-widget-icon {
    position: absolute;
    z-index: 10;
    font-size: 18px;
    line-height: 38px;
    left: 13px;
    top: 0;
    color: $gray-600;
  }

  .search-widget-icon-close {
    right: 7px;
    left: auto !important;
  }

  @media (max-width: 1023.99px) {
    padding-left: calc(#{$grid-gutter-width} / 2);
  }
}

// Mega menu

.megamenu-list {
  li {
    position: relative;
    padding: 5px 0px;

    a {
      color: $dropdown-color;
    }
  }
}

// @media (max-width: 767.98px) {
//   .logo {
//     span.logo-lg {
//       display: none;
//     }

//     span.logo-sm {
//       display: inline-block;
//     }
//   }
// }

.header-item {
  height: $header-height;
  display: flex;
  align-items: center;
}

.header-profile-user {
  height: 32px;
  width: 32px;
}

.topbar-badge-sm {
  right: 0;
  top: 7px !important;
}

.topbar-badge {
  right: -9px;
  top: 4px !important;
}

.topbar-user {
  @media (min-width: 768px) {
    background-color: $topbar-user-bg;
  }

  .dropdown-menu {
    top: 6px !important;
  }
}

.notification-item {
  padding: 0.75rem 1rem;
  white-space: inherit;
  position: relative;

  .form-check-input {
    position: relative;
    z-index: 2;
  }
}

// Dropdown with Icons
.dropdown-icon-item {
  display: block;
  border-radius: 3px;
  line-height: 34px;
  text-align: center;
  padding: 15px 0 9px;
  border: 1px solid transparent;
  color: var(--#{$prefix}dropdown-link-color);

  img {
    height: 24px;
  }

  span {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover {
    background-color: var(--#{$prefix}dropdown-link-hover-bg);
  }
}

// Full Screen
.fullscreen-enable {
  [data-toggle="fullscreen"] {
    .bx-fullscreen::before {
      content: "\eacb";
    }
  }
}

// Dark mode enable

// [data-layout-mode="dark"] {
//   .light-dark-mode {
//     .bx-moon::before {
//       content: "\ec34";
//     }
//   }
// }

// [data-topbar="dark"] {
//   #page-topbar {
//     background-color: $header-bg-dark;
//     border-color: $header-bg-dark;
//   }

//   .navbar-header {
//     .btn-topbar {
//       color: $header-item-color-dark;

//       &:hover,
//       &:focus {
//         background-color: rgba($white, 0.07);
//         color: $white;
//       }
//     }
//   }

//   .topbar-user {
//     @media (min-width: 767.99px) {
//       background-color: $topbar-user-bg-dark;
//     }

//     .user-name-text {
//       color: rgba($white, 0.85) !important;
//     }

//     .user-name-sub-text {
//       color: $header-item-color-dark !important;
//     }
//   }

//   .logo-dark {
//     display: none;
//   }

//   .logo-light {
//     display: inline-block;
//   }

//   .app-search {
//     .form-control {
//       background-color: rgba($white, 0.05);
//       color: $white;
//     }

//     span.search-widget-icon,
//     input.form-control::-webkit-input-placeholder {
//       color: rgba($white, 0.5);
//     }
//   }

//   .hamburger-icon {
//     span {
//       background-color: $gray-300;
//     }
//   }
// }

@media (max-width: 600px) {
  .navbar-header {
    .dropdown {
      position: static;

      .dropdown-menu {
        width: 100%;
      }
    }
  }
}

@media (max-width: 767.98px) {
  #search-dropdown-reponsive {
    top: 54px !important;
  }
}

[data-layout="vertical"] {
  &[data-layout-style="detached"] {
    @media (min-width: 1024.1px) {
      #page-topbar {
        left: 0 !important;
        box-shadow: $box-shadow;
      }

      .horizontal-logo {
        display: inline-block;
        padding-left: 0;
      }

      .topnav-hamburger {
        visibility: hidden;
      }

      .layout-width {
        max-width: 95%;
        margin: 0 auto;
      }

      &[data-sidebar-size="sm"],
      &[data-sidebar-size="sm-hover"] {
        .navbar-brand-box {
          background-color: transparent !important;
          position: relative;
          width: auto;
          text-align: left;

          .logo-sm {
            display: none;
          }

          .logo-lg {
            display: block;
          }
        }
      }
    }

    &[data-topbar="dark"] {
      .horizontal-logo {
        .logo-dark {
          display: none;
        }

        .logo-light {
          display: block;
        }
      }
    }
  }
}

[data-layout="horizontal"] {
  #page-topbar {
    left: 0;
    border-bottom: 1px solid var(--#{$prefix}gray-300);

    @media (min-width: 1024.1px) {
      &.topbar-shadow {
        box-shadow: none;
      }
    }
  }

  .page-content {
    @media (min-width: 1024.1px) {
      margin-top: $header-height;
    }

    padding: calc(45px + #{$grid-gutter-width}) calc(#{$grid-gutter-width} / 2)
      $footer-height calc(#{$grid-gutter-width} / 2);
  }

  &[data-layout-width="boxed"] {
    .page-content {
      @media (min-width: 1024.1px) {
        min-height: calc(100vh - #{$footer-height + $header-height});
      }
    }
  }
}

[data-layout="vertical"] {
  &[data-sidebar-size="sm"],
  &[data-sidebar-size="sm-hover"] {
    #page-topbar {
      @media (min-width: 768px) {
        // left: $vertical-menu-width-sm;
        left: 62px;
      }
    }
  }

  &[data-sidebar-size="md"] {
    #page-topbar {
      @media (min-width: 768px) {
        left: $vertical-menu-width-md;
      }
    }
  }
}

[data-layout="twocolumn"] {
  #page-topbar {
    @media (min-width: 768px) {
      left: calc(#{$twocolumn-menu-iconview-width} + #{$twocolumn-menu-width});
    }
  }

  .horizontal-logo {
    display: none;
  }
}

#account-menu {
  .MuiMenuItem-root {
    padding: 10px 40px 10px 20px;
  }
}


// Dashboard Setting

.dashboard-setting {
  min-width: 420px;
  padding: 30px;
  @media (max-width: 767px) {
    min-width: 300px;
  }
  .label-grid {
    position: relative;
    border:1px solid #e5e5e5;
    padding: 15px 50px 15px 55px;
    margin: 0;
    width: 100%;
    border-radius: 6px;
    .svg-icon {
      font-size: 22px;
      position: absolute;
      left: 20px;
      top: 16px;
    }
    .cm-input {
      position: absolute;
      right: 15px;
      top: 16px;
      padding: 0;
      margin: 0;
    }
  }
}


.login-dropdown {
  ul {
    li {
      @media (max-width: 767px) {
        font-size: 14px;
        padding: 4px 15px !important;
        min-width: 220px;
      }
      svg {
        @media (max-width: 767px) {
            width: 20px;
            height: 20px;
        }
      }
      .MuiListItemIcon-root {
        @media (max-width: 767px) {
          min-width: 28px;
        }
      }
    }
  }
}