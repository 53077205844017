.user-progressbar {
  .progress-bar {
    background-color: #fd5e75;
  }
}
.conversions-progressbar {
  .progress-bar {
    background-color: #8973d5;
  }
}

// Uptime Report Detail Css Start

.uptimeReport-detail {
  .circle,
  .circle::before {
    content: " ";
    margin: 15px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;
    background-color: #3bd671;
  }

  .circle::before {
    animation: mymove 2s infinite;
    position: absolute;
    background-color: #00ff00;
  }

  @keyframes mymove {
    50% {
      transform: scale(1.5);
      opacity: 0;
    }
    100% {
      transform: scale(1.5);
      opacity: 0;
    }
  }
  .status-card {
    color: $primary;
    .card-body {
      display: flex;
      flex-direction: column;
    }
    .title {
      font-size: 14px;
      color: $primary;
      font-weight: 500;
    }
    .value {
      font-size: 18px;
      font-weight: 600;
    }
    .subtitle {
      font-size: 14px;
      font-weight: 500;
    }
  }
  .incident-column {
    @media (min-width: 1200px) {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: 60%;
        background: #e4e8eb;
      }
      &:last-child {
        border: 0;
        &::before {
          display: none;
        }
      }
    }
  }
  .incident-card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 17px;
    height: 100%;
    color: $primary;
    .title {
      text-transform: capitalize;
      font-size: 14px;
      margin-bottom: 5px;
      font-weight: 500;
    }
    .value {
      font-weight: 700;
      font-size: 24px;
      margin-bottom: 5px;
    }
    .subtitle {
      font-size: 14px;
    }
    .form-control {
      background: #f5f7f8;
      border-radius: 8px;
    }
  }
  .responce-table-card {
    text-align: center;
    padding: 20px;
    @media (max-width: 767.98px) {
      padding: 20px 0;
    }
    .value {
      font-size: 30px;
      font-weight: 600;
      color: $primary;
      width: 100%;
      @media (max-width: 767.98px) {
        font-size: 16px;
      }
      @media (min-width: 768px) and (max-width: 1599.98px) {
        font-size: 20px;
      }
    }
    .title {
      font-size: 16px;
      width: 100%;
      @media (max-width: 767.98px) {
        font-size: 14px;
      }
    }
  }
  .domin-card {
    color: $primary;
    .main-title {
      font-weight: 700;
      margin-bottom: 15px;
    }
    .title {
      font-size: 14px;
      color: $primary;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .value {
      font-size: 18px;
      font-weight: 600;
      margin: 0;
      svg {
        font-size: 19px;
        path {
          fill: #ced4da;
        }
      }
    }
    .subtitle {
      font-size: 14px;
      font-weight: 500;
    }
    .btn {
      padding: 10px;
      font-size: 14px;
    }
    .user-box {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      img {
        width: 40px;
        height: 40px;
      }
      img,
      .profile-image {
        margin-right: 7px !important;
      }
      .name {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 0;
      }
      .designation {
        margin-bottom: 0;
        font-size: 12px;
      }
    }
  }
}
.main-link {
  text-decoration: underline !important;
}

// Performance Details Css Start

.label {
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  margin-top: 15px;
}

.gread {
  background-color: rgba(91, 200, 112, 0.1);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  .label {
    margin-bottom: 0;
    margin-top: 0;
    color: #5bc870;
    font-size: 60px;
    font-weight: 700;
  }
}

.browser-card {
  text-align: center;
  .browser-header {
    font-size: 16px;
    font-weight: 600;
    text-transform: capitalize;
    color: #495057;
  }
  .browser-text {
    color: #1e283b;
    background: #f8f8f8;
    font-weight: 800;
    font-size: 20px;
    // width: 130px;
    // height: 130px;
    border-radius: 30px;
    padding: 13px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 10px;
  }
}

.border-right {
  &::after {
    content: "";
    top: 10%;
    right: 0;
    position: absolute;
    width: 1px;
    height: 100px;
    background-color: #d8dbdd;
  }
}
.loaded-time {
  position: relative;
  text-align: center;
  .time-text {
    background: #1e283b;
    color: #fff;
    border-radius: 30px;
    padding: 12px 60px;
    font-size: 18px;
    font-weight: 700;
    display: block;
    margin: auto;
    width: fit-content;
    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 30%;
      height: 1px;
      background: #1e283b;
    }
    &::after {
      left: 19%;
      width: 25%;
    }
    &::before {
      right: 19%;
      width: 25%;
    }
  }
  .title {
    text-transform: uppercase;
    font-size: 15px;
    text-align: center;
    color: #1e283b;
    font-weight: 500;
    margin-top: 12px;
  }
}
.performance-section {
  .right-box {
    @media screen and (min-width: 1400px) {
      padding-left: 65px;
    }
  }
}

// Website Security Css Start
.websecurity {
  .tab-panel {
    padding: 0;
  }
}
.websecurity-scanning {
  .main-card {
    padding: 30px 50px;
    @media screen and (max-width: 767.98px) {
      padding: 20px;
    }
    .card-title {
      margin-bottom: 30px;
      font-size: 30px;
      @media screen and (max-width: 1199.98px) {
        font-size: 24px;
      }
    }
  }
  .card-body {
    padding: 0;
  }
  .summary-container {
    border-radius: 0 0 16px 16px;
    padding: 40px 20px;
    @media screen and (min-width: 1600px) {
      padding: 50px;
    }
  }
  .main-title {
    font-size: 30px;
    font-weight: bold;
  }
  .main-subtitle {
    font-size: 14px;
    color: $primary;
  }
  .grade-card {
    background-color: #53a553;
    color: #fff;
    border-radius: 12px;
    position: relative;
    // padding: 30px;
    .card-body {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 50px;
    }
    .grade {
      font-size: 150px;
      text-transform: uppercase;
      font-weight: 700;
      font-style: unset;
    }
    .title {
      font-size: 18px;
      font-weight: 300;
      margin-bottom: 0;
    }
    .subtitle {
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 0;
    }
    .icon-box {
      position: absolute;
      left: 20px;
      top: 15px;
      background: rgb(255 255 255 / 15%);
      border-radius: 10px;
      width: 60px;
      height: 60px;
    }
  }
}

.report-card {
  border-radius: 8px;
  background-color: #ced4da;
  height: 100%;
  border: 0;
  margin-bottom: 0;
  padding: 0;
  cursor: pointer;
  .card-body {
    display: flex;
    align-items: center;
    padding: 23px 24px;
    @media screen and (min-width: 1200px) and (max-width: 1599.98px) {
      padding: 23px 17px;
    }
  }
  .title {
    font-size: 15px;
    color: $primary;
    font-weight: 600;
    margin-bottom: 0;
    margin-left: 20px;
    @media screen and (min-width: 1400px) and (max-width: 1600px) {
      margin-left: 10px;
      font-size: 14px;
    }
  }
  svg path {
    fill: $primary;
  }
}
.list-card {
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #e4e8eb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: start;
  color: $primary;
  padding: 11px 30px;
  .title {
    margin-bottom: 0px;
    font-size: 14px;
    font-weight: 500;
  }
  .subtitle {
    margin-bottom: 0;
    font-weight: 600;
    font-size: 16px;
  }
  svg {
    margin-right: 30px;
    path {
      fill: $primary;
    }
  }
}
.summary-card {
  border-radius: 12px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  .title {
    text-transform: uppercase;
    font-style: normal;
    font-size: 30px;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
  }
  .subtitle {
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 0;
    width: 57%;
    margin: auto;
    @media screen and (min-width: 1400px) and (max-width: 1600px) {
      width: 75%;
    }
  }
  .body-top {
    padding: 60px 20px 25px 20px;
    text-align: center;
    background-color: #53a553;
    height: 100%;
  }
  .body-bottom {
    color: #fff;
    margin-bottom: 0;
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    padding: 10px 8px;
    background-color: #5cb85c;
  }
  .icon-box {
    background-color: #00ff00;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    border-radius: 0 0 0 10px;
    svg {
      width: 30px;
      height: 30px;
      path {
        fill: #fff;
      }
    }
  }
  .top-green {
    background-color: #53a553;
  }
  .bottom-green {
    background-color: #5cb85c;
  }
  .top-orange {
    background-color: #e37b00;
  }
  .bottom-orange {
    background-color: #fd8900;
  }
  .top-red {
    background-color: #d84a2e;
  }
  .bottom-red {
    background-color: #ed5c40;
  }
  .top-yellow {
    background-color: #eead00;
  }
  .bottom-yellow {
    background-color: #ffbc0a;
  }
}
@media screen and (max-width: 767.98px) {
  .websecurity-scanning {
    .main-title {
      font-size: 24px;
    }
  }
  .grade-card {
    .card-body {
      flex-direction: column;
      gap: 0;
    }
    .grade {
      font-size: 100px;
    }
    .title {
      font-size: 16px;
    }
    .subtitle {
      font-size: 28px;
    }
  }
}
@media screen and (min-width: 991.98px) and (max-width: 1400px) {
  .websecurity-scanning {
    .main-title {
      font-size: 24px;
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .grade-card {
    .card-body {
      gap: 20px;
    }
    .grade {
      font-size: 120px;
    }
    .title {
      font-size: 18px;
    }
    .subtitle {
      font-size: 30px;
    }
    .icon-box {
      width: 40px;
      height: 40px;
    }
  }
  .report-card {
    .title {
      font-size: 13px;
    }
  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px) {
  .summary-card {
    .title {
      font-size: 22px;
    }
    .icon-box {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.security-card {
  // padding: 30px 50px;
  // @media screen and (max-width: 767.98px) {
  //   padding: 20px;
  // }
  // .card-title {
  //   font-size: 30px;
  //   @media screen and (max-width: 1199.98px) {
  //     font-size: 24px;
  //   }
  // }
  .label {
    text-align: start;
    position: relative;
    line-height: 2.5;
    color: $primary;
    font-weight: 700;
    &::before {
      content: "";
      position: absolute;
      width: 70%;
      height: 1px;
      left: 0;
      bottom: 1px;
      background: #e4e8eb;
    }
    &::after {
      content: "";
      position: absolute;
      width: 12%;
      height: 3px;
      left: 0;
      bottom: 0;
      background: #f99f1e;
      border-radius: 30px;
    }
    svg path {
      fill: #ced4da;
    }
  }
  .value {
    font-weight: 500;
  }
  .MuiChip-root {
    margin: 4px 4px 4px 0;
    background: #ced4da;
    color: $primary;
  }
  .MuiCheckbox-root {
    padding: 6px;
  }
  .MuiFormControlLabel-root {
    border-radius: 30px;
    background: #ced4da;
    .MuiSvgIcon-root {
      font-size: 20px;
      color: #fff;
    }
  }
  .MuiFormControlLabel-label {
    padding-right: 15px;
    color: $primary;
    font-size: 14px;
    text-transform: uppercase;
  }
}

.Soft-card {
  border-radius: 8px;
  overflow: hidden;
  background-color: #f1f1f1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  .top {
    color: #1e283b;
    font-weight: 600;
    font-size: 20px;
    text-align: start;
  }
  .bottom {
    background-color: #dbe0e9;
    padding: 5px 50px;
    border-radius: 30px;
    .text {
      font-weight: 500;
      font-size: 35px;
      margin-bottom: 0;
    }
  }
}

.software-security {
  .label-text {
    text-align: start;
    position: relative;
    font-size: 16px;
    font-weight: 700;
    padding-bottom: 7px;
    margin-bottom: 15px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    &:first-child {
      margin-top: 0;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 1px;
      background: #e4e8eb;
    }
    // &::after {
    //   content: "";
    //   position: absolute;
    //   width: 12%;
    //   height: 3px;
    //   left: 0;
    //   bottom: 0;
    //   background: #f99f1e;
    //   border-radius: 30px;
    // }
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: #ced4da;
      }
    }
  }
  .label-detail {
    margin-bottom: 50px;
  }
  .MuiChip-root {
    background-color: #f99f1e;
    color: #fff;
    margin-bottom: 5px;
    border-radius: 30px;
    margin-bottom: 0;
  }
  .MuiChip-root.gray-chip {
    background-color: #ced4da;
    color: $primary;
    padding: 5px 15px;
  }
  .description {
    background-color: #f5f7f8;
    color: $primary;
    font-size: 14px;
    border-radius: 30px;
    margin-bottom: 0;
    padding: 7px 15px 7px 20px;
    line-height: 1.2;
    @media screen and (max-width: 767.98px) {
      border-radius: 16px;
    }
  }
  .chip-box {
    background-color: #fd8900;
    border-radius: 30px;
    color: #fff;
    width: fit-content;
    overflow: hidden;
    min-width: 180px;
    display: flex;
    span {
      padding: 5px 12px;
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      width: 50%;
      &:last-child {
        background-color: #e37b00;
      }
    }
  }
  .show-more-btn {
    width: 50%;
    margin: auto auto 0 auto;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}
.compliance {
  .label-detail {
    background-color: #f5f7f8;
    color: $primary;
    font-size: 14px;
    border-radius: 50px;
    padding: 7px 7px 7px 25px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      padding: 12px;
    }
  }
  .description-text {
    width: 65%;
    margin-bottom: 0;
    font-size: 14px;
    color: $primary;
    line-height: 1.2;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .PCIDSS-text {
    width: 56%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .MuiChip-root {
    color: #fff;
    background-color: #5cb85c;
    margin-bottom: 5px;
    border-radius: 15px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 300;
    svg path {
      fill: #fff;
    }
  }
  .MuiChip-label {
    padding: 0 16px;
  }
  .green-label {
    background-color: #dff0d8 !important;
    .MuiChip-root {
      background-color: #5cb85c !important;
    }
  }
  .blue-label {
    background-color: #d9edf7 !important;
    .MuiChip-root {
      background-color: #337ab7 !important;
    }
  }
  .orange-label {
    background-color: #fcf8e3 !important;
    .MuiChip-root {
      background-color: #f0ad4e !important;
    }
  }
  @media screen and (max-width: 767.98px) {
    .label-detail {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      border-radius: 16px;
    }
  }
}
.Header-security {
  .header-label {
    margin-bottom: 30px;
  }
  .green {
    .MuiChip-root {
      background-color: #5cb85c !important;
    }
  }
  .blue {
    .MuiChip-root {
      background-color: #337ab7 !important;
    }
  }
  .orange {
    .MuiChip-root {
      background-color: #f0ad4e !important;
    }
  }
  .directives-table {
    th {
      font-weight: 700;
      color: $primary;
      font-size: 16px;
    }
    td {
      font-weight: 400;
      color: $primary;
      font-size: 14px;
    }
  }
}

.Websecurity-history {
  .card {
    border-radius: 0 0 16px 16px;
    padding: 40px 20px;
    @media screen and (min-width: 1600px) {
      padding: 50px;
    }
    .card-body {
      padding: 0;
    }
  }

  .report-table-menu {
    svg {
      path {
        fill: #4c525e;
        // opacity: 1;
      }
      rect {
        fill: #4c525e;
        // opacity: 1;
      }
    }
  }
}

.performance-matrix {
  .grade-box {
    .main-title {
      font-weight: 700;
      margin-bottom: 15px;
      gap: 10px;
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: #ced4da;
        }
      }
      @media screen and (min-width: 1200px) and (max-width: 1400px) {
        font-size: 24px;
      }
    }
  }
  .matrix-container {
    border-radius: 16px;
    overflow: hidden;
  }
  .matrix-box {
    background-color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding: 15px;
    .grade {
      font-size: 120px;
      font-weight: 700;
      margin-bottom: 0;
      line-height: 0.8;
      @media screen and (min-width: 1200px) and (max-width: 1600px) {
        font-size: 100px;
        line-height: 1;
      }
    }
    .title {
      font-size: 16px;
      svg {
        width: 16px;
        height: 16px;
        path {
          fill: #ced4da;
        }
      }
      @media screen and (min-width: 1200px) and (max-width: 1440px) {
        font-size: 14px;
      }
    }
    .value {
      font-size: 50px;
      font-weight: 400;
      margin-bottom: 0;
      display: flex;
      align-items: flex-start;
      span {
        font-size: 30px;
        line-height: 1.5;
      }
      @media screen and (min-width: 1200px) and (max-width: 1449.98px) {
        font-size: 33px;
        span {
          font-size: 20px;
        }
      }
      @media screen and (min-width: 1450px) and (max-width: 1600px) {
        font-size: 40px;
        span {
          font-size: 26px;
        }
      }
    }
  }
}

.report-table {
  th,
  td {
    padding: 8px;
    border: 1px solid #e9e9e9 !important;
  }
  th {
    font-weight: 400;
  }
}

.report-grid-card {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  @media (max-width: 767.98px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1600px) {
    grid-template-columns: repeat(5, 1fr);
  }
}
