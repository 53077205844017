//
// Google font - Poppins
//

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

//
// Google font - Open Sans
//

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

//
// Premium Font : HKGrotesk
//

@font-face {
  font-family: "hkgrotesk";
  src: url("../../fonts/hkgrotesk-light.eot");
  src: local("hkgrotesk light"),
    url("../../fonts/hkgrotesk-light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "hkgrotesk";
  src: url("../../fonts/hkgrotesk-regular.eot");
  src: local("hkgrotesk regular"),
    url("../../fonts/hkgrotesk-regular.woff") format("woff");
  font-weight: 400;
}
@font-face {
  font-family: "hkgrotesk";
  src: url("../../fonts/hkgrotesk-medium.eot");
  src: local("hkgrotesk medium"),
    url("../../fonts/hkgrotesk-medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "hkgrotesk";
  src: url("../../fonts/hkgrotesk-semibold.eot");
  src: local("hkgrotesk semibold"),
    url("../../fonts/hkgrotesk-semibold.woff") format("woff");
  font-weight: 600;
}
@font-face {
  font-family: "hkgrotesk";
  // src: url("../../fonts/hkgrotesk-bold.eot");
  src: local("hkgrotesk bold"),
    url("../../fonts/hkgrotesk-bold.woff") format("woff");
  font-weight: 700;
}
