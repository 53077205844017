$pp_orange: #f99f1e !important;
body {
  background-color: #f8f8f8;
  // &:before {
  //     position: fixed;
  //     content: "";
  //     top: 0;
  //     right: 0;
  //     height: 3px;
  //     left: 0;
  //     z-index: 9999;
  //     margin: 0;
  //     border-radius: inherit;
  //     background: linear-gradient(130deg,#df4f43,#f99f1e 60.07%,#19a362 90.05%);
  // }
}

body .thumbnail-container,
body .thumbnail_container {
  padding-bottom: 66.67%;
  overflow: hidden;
  position: relative;
  width: 100%;
  -webkit-border-radius: 0;
  -khtml-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-radius: 0;
}
body .thumbnail {
  margin: 0;
  display: block;
  padding: 0;
  border: none;
  height: 100%;
  width: 100%;
  position: absolute;
  background: 0 0;
}
body .thumbnail a,
body .thumbnail img {
  display: block;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  width: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  position: absolute;
}
.inner-border-0 {
  * {
    border: 0;
  }
}
.remove-inner-bg {
  *:not(.shadow-sm) {
    //background-color: transparent;
  }
}
.pp_c_orange {
  color: $pp_orange;
}
.pp_b_orange {
  background-color: $pp_orange;
}
a {
  &:hover {
    svg.text-muted,
    i.text-muted,
    i.text-danger,
    i.text-success,
    i.text-warning {
      color: var(--vz-link-color) !important;
    }
  }
}
.profile-timeline .accordion-item::before {
  left: 39px;
}
.topbar-user {
  background: transparent;
}
// .navbar-menu {
//   .navbar-nav {
//     padding-left: 1.2rem;
//     .nav-item.active {
//       position: relative;
//     }
//   }
// }

.navbar-menu {
  border: none;
  &.tab_right_detail {
    position: static !important;
    width: 100% !important;
    background: transparent !important;
    border-color: transparent;
    .navbar-nav {
      .navbar-item {
        margin: 5px 0;
      }
      .nav-link {
        font-size: 14px !important;
        color: var(--vz-body-color);
        position: relative;
        span {
          color: var(--vz-body-color);
        }
        &.menu-link {
          &:after {
            color: var(--vz-vertical-menu-title-color) !important;
          }
        }
      }
    }
  }
}
.nav-tabs-customs {
  border-bottom: 0;
  // li {
  //   + li {
  //     margin-left: 30px;
  //   }
  // }
  .nav-link {
    // padding: 0 0 20px;
    background: #e3eaee;
    color: $primary;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    &.active {
      // color: $pp_orange;
      background: transparent;
      // position: relative;
      // &:after {
      //   content: "";
      //   position: absolute;
      //   height: 3px;
      //   width: 100%;
      //   bottom: 0;
      //   left: 0;
      //   background-color: $pp_orange;
      // }
    }
  }
}
.custom-check {
  input {
    position: absolute;
    left: -9999px;
    &:focus {
      + label {
        box-shadow: none;
      }
    }
    &:checked,
    &:hover {
      + label {
        color: var(--vz-heading-color);
        padding: 0 0 0 30px;
        //	background-color: #f3f6f9;
        &:before {
          border: 1px solid var(--vz-input-border);
          opacity: 0;
        }
        &:after {
          opacity: 1;
        }
      }
    }
  }
  label {
    display: block;
    position: relative;
    margin: 0;
    padding: 0 0 0 30px;
    //border: 1px solid var(--vz-input-border);
    border-radius: 5px;
    color: var(--vz-heading-color);
    //background-color: #fff;
    box-shadow: none;
    white-space: nowrap;
    cursor: pointer;
    white-space: normal;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 3px;
      bottom: 10px;
      left: 0;
      width: 17px;
      height: 17px;
      border: 1px solid var(--vz-input-border);
      border-radius: 100px;
    }
    &:after {
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      left: 6px;
      right: 0;
      top: -6px;
      width: 8px;
      height: 18px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      transform-origin: bottom left;
      transform: rotate(45deg);
      opacity: 0;
    }
    &:hover {
      box-shadow: none;
    }
  }
}

.cust-shadow {
  box-shadow: 0 5px 7px rgba(0, 0, 0, 5%);
}
.navbar-header {
  box-shadow: none;
}
.title_breadcum {
  padding: calc(47px / 2) 0;
  color: $primary;
  font-size: 20px;
  width: 350px;
  @media screen and (max-width: "767.98px") {
    font-size: 16px;
  }
}
.navbar-footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.navbar-footer .quick-menu {
  margin: 0;
  padding: 0;
  padding-left: 1.2rem;
  border-width: 1px 0 1px 0;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-footer .quick-menu li {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.navbar-footer .quick-menu li + li {
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-footer .quick-menu li a {
  color: #8c9098;
  opacity: 1;
  transition: all 0.5s ease-in-out;
  display: block;
  padding: 10px 0.75rem;
  display: flex;
  align-items: center;
  font-size: 16px;
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 14px;
  }
}
.navbar-footer .quick-menu li a:hover {
  opacity: 0.7;
}
.navbar-footer .design-by {
  padding-left: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 0px solid #4c4c4c;
  color: #8c9098;
  font-size: 12px;
  @media (min-width: 992px) and (max-width: 1200px) {
    font-size: 10px;
  }
  span {
    padding: 1.2rem 0.75rem;
  }
  a {
    color: #8c9098;
  }
}
.navbar-footer .design-by svg {
  max-width: 120px;
  max-height: 55px;
}
.navbar-footer .design-by .copyright {
  position: relative;
}
.navbar-footer .design-by .copyright svg {
  width: 19px;
  height: 19px;
  fill: #ffffff;
}
.navbar-footer .design-by .copyright .hideshow {
  font-size: 12px;
  white-space: nowrap;
  position: absolute;
  left: 25px;
  top: 0;
  transition: all 0.4s ease-in-out;
  background: var(--vz-body-bg);
  transform-origin: left center;
  display: inline-block;
  z-index: 1;
  padding: 3px 10px;
  border-radius: 20px;
  opacity: 0;
  visibility: hidden;
  transform: translateX(-15px);
  -webkit-transform: translateX(-15px);
  box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
}
.navbar-footer .design-by .copyright:hover .hideshow {
  opacity: 1;
  visibility: visible;
  transform: translateX(0px);
  -webkit-transform: translateX(0px);
}
[data-layout="vertical"][data-sidebar-size="sm"] .navbar-footer .quick-menu {
  display: block !important;
}
[data-layout="vertical"][data-sidebar-size="sm"] .navbar-footer .quick-menu li {
  width: 100%;
}
[data-layout="vertical"][data-sidebar-size="sm"]
  .app-menu.navbar-menu
  #scrollbar {
  max-height: calc(100vh - 400px);
}
[data-layout="vertical"][data-sidebar-size="sm"]
  .after-userlogin
  .userbtn
  .text-start {
  display: none;
}
[data-layout="vertical"][data-sidebar-size="sm"]
  .navbar-footer
  .design-by
  .copyright {
  display: none !important;
}
.navbar-footer .quick-menu li + li {
  border-color: transparent;
}
header .develop-by svg {
  max-width: 160px;
  width: 100%;
}
[data-layout="vertical"][data-sidebar-size="sm"] .navbar-menu {
  position: fixed;
}
[data-layout="vertical"][data-sidebar-size="sm"] .design-by {
  justify-content: center;
}
[data-layout="vertical"][data-sidebar-size="sm"]
  .navbar-menu
  .navbar-nav
  .nav-item
  .com_table {
  tr {
    box-shadow: 0 5px 7px rgba(0, 0, 0, 5%);
    border-top: transparent;
    border-bottom: transparent;
  }

  th {
    --vz-text-opacity: 1;
    color: rgba(var(--vz-black-rgb), var(--vz-text-opacity)) !important;
    font-weight: bold;
    background-color: #f3f6f9;
  }
  td {
    color: #3f3f40 !important;
  }
}
.chat-conversation {
  height: calc(100vh - 550px);
}
.page-content {
  padding: calc(70px + 1.8rem) 60px 60px 60px;
  background: #f8f8f8;
  @media screen and (max-width: 767.98px) {
    padding: calc(70px + 1.8rem) 20px 20px 20px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199.98px) {
    padding: calc(70px + 1.8rem) 30px 30px 30px;
  }
}
.nv_tab_com {
  .nav-item {
    margin-right: 6px;
    &:last-child {
      margin-right: 0;
    }
  }
  .nav-link {
    background-color: #f1f3f6;
    color: #757575;
    font-size: 14px;
    font-weight: 600;
    &.active {
      background-color: #ced4da !important;
      color: $primary !important;
    }
  }
}
.hover-icon {
  .icon {
    opacity: 0;
    pointer-events: none;
    box-shadow: none;
    border: none;
    background: transparent;
    padding: 0;
  }
  &:hover {
    .icon {
      opacity: 1;
      pointer-events: all;
    }
  }
}
.choices__list--single {
  .choices__item {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.navbar-menu .navbar-nav .nav-sm .nav-link {
  &.active,
  &:hover {
    color: $pp_orange;
    &:before {
      background-color: $pp_orange;
    }
  }
}
.cust-tab_one {
  // border-bottom: none;
  //    z-index: 11;
  margin-bottom: 5px;
  //position: relative;
  background-color: #f3f6f9;
  padding: 10px;
  border-radius: 50px;
  .nav-link {
    background-color: #ffffff !important;
    border: none !important;
    padding: 7px 15px !important;
    border-radius: 50px !important;
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 5px;
      background: transparent;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 5px 5px 0 0;
    }
    &.active {
      background-color: var(--vz-link-color) !important;
      svg {
        color: #ffffff !important;
      }
      .title {
        color: #ffffff !important;
      }
    }
    p {
      color: var(--vz-gray-700);
    }
  }
  li {
    + li {
      margin-left: 10px !important;
    }
  }
  .title {
    font-size: 14px;
  }
  .text {
    font-size: 12px;
  }
}
.cust-left_p {
  padding: 0 60px 0 60px !important;
}
[data-layout="vertical"][data-sidebar-size="sm"] {
  .topbar-user {
    .text-start {
      display: none;
    }
  }
}
// Sidebar Light Start
// [data-layout-mode="light"] {
// .navbar-menu {
//   // background: rgb(30, 40, 59);
//   // border-right-color: rgb(30, 40, 59);
//   .navbar-nav {
//     .nav-item {
//       // position: relative;
//       .nav-item {
//         .menu-link {
//           padding: 0.75rem 0.93rem 0.2rem;
//           &:before {
//             content: none !important;
//           }
//         }
//       }
//       // .menu-link {
//       //   color: #8c9098;
//       //   &.active {
//       //     color: $primary;
//       //     background-color: #f5f7f8;
//       //     // padding: 0.5rem;
//       //     position: relative;
//       //     z-index: 1;
//       //     margin: 12px auto;
//       //     text-align: center;
//       //     border-start-start-radius: 6px;
//       //     border-start-end-radius: 0;
//       //     border-end-end-radius: 0;
//       //     border-end-start-radius: 6px;
//       //     margin-inline-start: 0;
//       //     border: 0px solid transparent;
//       //     border-inline-end: 0px;
//       //     width: 100%;
//       //     svg,
//       //     i {
//       //       color: $pp_orange;
//       //     }
//       //     svg {
//       //       fill: $pp_orange;
//       //       path {
//       //         fill: $pp_orange;
//       //       }
//       //     }
//       //     &::before {
//       //       content: "";
//       //       position: absolute;
//       //       top: -30px;
//       //       z-index: 9;
//       //       inset-inline-end: 0px;
//       //       bottom: 0;
//       //       height: 30px;
//       //       border-top-color: transparent;
//       //       border-left-color: transparent;
//       //       border-bottom: transparent;
//       //       border-start-start-radius: 0;
//       //       border-start-end-radius: 0;
//       //       border-end-end-radius: 12px;
//       //       border-end-start-radius: 0;
//       //       border-inline-end: 20px solid $primary;
//       //     }
//       //     &::after {
//       //       content: "";
//       //       position: absolute;
//       //       top: 51px;
//       //       z-index: 0;
//       //       inset-inline-end: 0px;
//       //       bottom: 0;
//       //       height: 30px;
//       //       border-top-color: transparent;
//       //       border-left-color: transparent;
//       //       border-bottom: transparent;
//       //       border-start-start-radius: 0;
//       //       border-start-end-radius: 12px;
//       //       border-end-end-radius: 0;
//       //       border-end-start-radius: 0;
//       //       border-inline-end: 20px solid $primary;
//       //     }
//       //     .shape1 {
//       //       position: absolute;
//       //       top: -30px;
//       //       width: 20px;
//       //       height: 60px;
//       //       inset-inline-end: 0px;
//       //       background: #f5f7f8;
//       //     }
//       //     .shape2 {
//       //       position: absolute;
//       //       top: 35px;
//       //       width: 20px;
//       //       height: 30px;
//       //       inset-inline-end: 0px;
//       //       background: #f5f7f8;
//       //     }
//       //   }
//       // }
//     }
//   }
// }
// }
// Sidebar Light End

[data-layout="vertical"][data-sidebar-size="sm"]
  .navbar-menu
  .navbar-nav
  .nav-item:hover
  > .menu-dropdown {
  background-color: #1e283b;
}
.cms a,
.cms a:active,
.cms a:focus,
.cms a:hover {
  text-decoration: none;
  -webkit-transition: all 0.5s ease-in-out;
  -khtml-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: 0;
  outline-offset: 0;
}
.cms figure.table,
.cms ol,
.cms p,
.cms table,
.cms ul {
  margin-bottom: 25px;
}
.cms blockquote {
  display: inline-block;
  padding: 15px;
  border-left: 3px solid #aa182c;
  background-color: #f2f2f2;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.12);
}
.cms blockquote a b,
.cms blockquote a i,
.cms blockquote a span,
.cms blockquote a strong,
.cms blockquote a u {
  color: inherit !important;
}
.cms p {
  line-height: 150%;
  letter-spacing: 0;
  word-spacing: 0;
  text-transform: inherit;
  display: block;
}
.cms p b,
.cms p strong {
  display: inline;
}
.cms p a b,
.cms p a i,
.cms p a span,
.cms p a strong,
.cms p a u {
  color: inherit !important;
}
.cms img.left {
  float: left;
  margin: 0 25px 25px 0;
}
.cms img.right {
  float: right;
  margin: 0 0 25px 25px;
}
.cms .h1,
.cms .h2,
.cms .h3,
.cms .h4,
.cms .h5,
.cms .h6,
.cms h1,
.cms h2,
.cms h3,
.cms h4,
.cms h5,
.cms h6 {
  line-height: 120%;
  letter-spacing: 0;
  word-spacing: 0;
  text-transform: inherit;
  padding: 0;
  letter-spacing: 0.2px;
}
.cms .h1,
.cms h1 {
  line-height: 150%;
  margin: 0;
}
.cms .h2,
.cms h2 {
  text-transform: uppercase;
  line-height: 130%;
  margin-top: 0;
  margin-bottom: 15px;
}
.cms .h2 b,
.cms .h2 i,
.cms .h2 span,
.cms .h2 strong,
.cms h2 b,
.cms h2 i,
.cms h2 span,
.cms h2 strong {
  color: inherit !important;
}
.cms .h3,
.cms h3 {
  line-height: 130%;
}
.cms .h3 b,
.cms .h3 i,
.cms .h3 span,
.cms .h3 strong,
.cms h3 b,
.cms h3 i,
.cms h3 span,
.cms h3 strong {
  color: inherit !important;
}
.cms .h4,
.cms h4 {
  line-height: 130%;
}
.cms .h4 b,
.cms .h4 i,
.cms .h4 span,
.cms .h4 strong,
.cms h4 b,
.cms h4 i,
.cms h4 span,
.cms h4 strong {
  color: inherit !important;
}
.cms .h5,
.cms h5 {
  line-height: 130%;
}
.cms .h5 b,
.cms .h5 i,
.cms .h5 span,
.cms .h5 strong,
.cms h5 b,
.cms h5 i,
.cms h5 span,
.cms h5 strong {
  color: inherit !important;
}
.cms .h6,
.cms h6 {
  line-height: 130%;
}
.cms .h6 b,
.cms .h6 i,
.cms .h6 span,
.cms .h6 strong,
.cms h6 b,
.cms h6 i,
.cms h6 span,
.cms h6 strong {
  color: inherit !important;
}

.cms img {
  max-width: 100%;
  vertical-align: middle;
}
.cms ol,
.cms ul {
  padding: 0;
}
.cms ol li,
.cms ul li {
}
.cms ol li + li,
.cms ul li + li {
  margin-top: 15px;
}
.cms ol li::first-letter,
.cms ul li::first-letter {
  text-transform: capitalize;
}

.cms ol,
.cms ul {
  margin-left: 0;
  position: relative;
  left: 0;
}
.cms ol li,
.cms ul li {
  line-height: 150%;
}
.cms ul > li {
  padding-left: 10px;
  position: relative;
  list-style-position: inside;
}
.cms ul > li b,
.cms ul > li strong {
  display: inline-block;
}
.cms ol li {
  padding-left: 10px;
}
.cms ol li:before {
  content: none;
}
.cms ol ul li {
  padding-left: 10px;
  position: relative;
}
.cms ol ul li b,
.cms ol ul li strong {
  display: inline-block;
}

.rating {
  position: relative;
  height: 25px;
  line-height: 25px;
  font-size: 25px;
}

.rating label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  cursor: pointer;
}

.rating label:last-child {
  position: static;
}

.rating label:nth-child(1) {
  z-index: 5;
}

.rating label:nth-child(2) {
  z-index: 4;
}

.rating label:nth-child(3) {
  z-index: 3;
}

.rating label:nth-child(4) {
  z-index: 2;
}

.rating label:nth-child(5) {
  z-index: 1;
}

.rating label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.rating label .icon {
  float: left;
  color: transparent;
  margin: 0 2px;
}

.rating label:last-child .icon {
  color: #c0c0c0;
}

.rating:not(:hover) label input:checked ~ .icon,
.rating:hover label:hover input ~ .icon {
  color: #ffbc0a;
}

.rating label input:focus:not(:checked) ~ .icon:last-child {
  color: #c0c0c0;
  text-shadow: 0 0 5px #09f;
}
.team-list.grid-view-filter .team-box .thumbnail-container {
  padding-bottom: 49.17%;
  background-color: #f2f2f2;
}

.inputeditable {
  position: relative;
  cursor: text;
  transition: background-color 0.5s;
  border-radius: 8px;
  border: none;
  box-shadow: 0px 0px 0px 1.5px #ffffff;
  padding: 0.3rem 35px 0.3rem 0.3rem;
}
.inputeditable:hover {
  background-color: #f4f5f6;
}
.inputeditable .input {
  resize: none;
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: inherit;
  box-sizing: border-box;
  background: none;
  outline: none;
  background-color: #ffffff;
  top: 0px;
  left: 0;
  box-shadow: 0px 0px 0px 1.5px #0ebeff;
  border: none;
  padding: 0.3rem 35px 0.3rem 0.3rem;
}

/* WorkFlow */

.flow_form {
  text-align: center;
}

.flow_form .form-group {
  margin-bottom: 15px;
}

.flow_form .user_fill {
  border: 1px solid #c2cad8;
  padding: 10px 25px;
  display: inline-block;
  border-radius: 20px;
  cursor: default;
  font-weight: 600;
}

.flow_form .arrow_line span {
  position: relative;
  height: 60px;
  display: table;
  margin: auto;
}

.flow_form .arrow_line span:before {
  position: absolute;
  content: "";
  background-color: #080655;
  left: 0px;
  top: 0px;
  width: 1px;
  height: 90%;
}

.flow_form .arrow_line span:after {
  position: absolute;
  content: "";
  display: block;
  border-width: 0px 1px 1px 0px;
  border-style: solid;
  left: -4px;
  bottom: 0px;
  width: 9px;
  height: 9px;
  border-color: rgba(3, 169, 244, 0) #080655 #080655 rgba(3, 169, 244, 0);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.flow_form .input_box {
  width: 200x;
  margin: auto;
  display: inline-block;
}

.flow_form .select2-container--bootstrap {
  width: 100% !important;
}

.flow_form .after_msg {
  position: relative;
  width: 150px;
  margin: auto;
}

.flow_form .after_msg .right {
  position: absolute;
  left: 115%;
  top: 0px;
}

.flow_form .after_msg .right .input_box {
  width: 250px;
}

.flow_form .after_msg .right .input_box textarea.form-control {
  resize: none;
}

.flow_form .customer_div {
  width: 425px;
  margin: 50px auto 50px;
  position: relative;
  min-height: 125px;
}

.flow_form .customer_div .customer_box {
  position: relative;
  z-index: 1;
  background: #fff;
  width: 125px;
  height: 125px;
  margin: auto;
  border: 1px solid #c2cad8;
  display: table;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.flow_form .customer_div .customer_box span {
  display: table-cell;
  vertical-align: middle;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.flow_form .customer_div .customer_box label.switch_btn {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: relative;
  top: 11px;
  margin-bottom: -5px;
}

.flow_form .customer_div .row_customer {
  border-top: 0px solid #00a3e3;
  margin-top: -63px;
}

.flow_form .customer_div .row_customer .arrow_line span {
  height: 100px;
}

.flow_form .customer_div .row_customer .left .arrow_line span {
  left: -50%;
}

.flow_form .customer_div .row_customer .right .arrow_line {
  margin-right: 1px;
}

.flow_form .customer_div .row_customer .right .arrow_line span {
  right: -50%;
}

.flow_form .customer_div .row_customer .arrow_line {
  position: relative;
  z-index: 0;
}

.flow_form .customer_div .row_customer .arrow_line:before {
  position: absolute;
  content: "";
  background: #00a3e3;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 1px;
}

.flow_form .customer_div .row_customer .label_title {
  position: relative;
  top: -126px;
  font-weight: 600;
  color: #616161;
  text-transform: uppercase;
}

.flow_form .customer_div .row_customer .left .row_inp_lf {
  position: relative;
  left: -50%;
}

.flow_form .customer_div .row_customer .right .row_inp_rh {
  position: relative;
  right: -50%;
}

.flow_form .customer_div .row_customer .right .row_inp_rh .form_title_reminder {
  white-space: nowrap;
  margin-left: -68px;
}

.flow_form .image-need {
  margin: 10px 0;
}

@media (max-width: 767px) {
  .flow_form {
    overflow: auto;
  }
  .flow_form .customer_div .row_customer .left .row_inp_lf {
    left: -20%;
  }
  .flow_form .customer_div .row_customer .right .row_inp_rh {
    right: -20%;
  }
  .flow_form
    .customer_div
    .row_customer
    .right
    .row_inp_rh
    .form_title_reminder {
    white-space: inherit;
    margin-left: 0px;
  }
}

@media (max-width: 479px) {
  .flow_form .customer_div {
    padding: 0 5px;
  }
  .flow_form .customer_div .row_customer .left .row_inp_lf {
    left: 0;
  }
  .flow_form .customer_div .row_customer .right .row_inp_rh {
    right: 0;
  }
}

.flow_form .moduls_box_approval {
  max-width: 845px;
  width: 100%;
  margin: auto;
}

.flow_form .side_moduls_box {
  max-width: 350px;
  width: 100%;
  margin: auto;
}

.flow_form .side_moduls_box .approval_div {
  color: green;
}

.flow_form .side_moduls_box .noapproval_div {
  color: #080655;
}

.flow_form .side_moduls_box .form_title a {
  font-size: 12px;
  text-transform: capitalize;
  border-bottom: 1px solid #080655;
  margin-left: 5px;
  margin-right: 5px;
}

.flow_form .side_moduls_box .form_title a:hover {
  border-bottom: 1px solid transparent;
}

.flow_form .select_moduls_box {
  overflow-y: auto;
}

.flow_form .select_moduls_box .form-control {
  overflow: inherit;
  min-height: 166px;
  border: 1px solid #c2cad8;
  padding: 6px 12px;
}

.flow_form .select_moduls_box .form-control option {
  padding: 5px 0px;
  border-bottom: 1px dashed #dcdee2;
  text-align: center;
}

.flow_form .select_moduls_box .form-control option:last-child {
  border-bottom: 0px;
}

.flow_form .moduls_select_buttons {
  padding-top: 40px;
  padding-bottom: 10px;
}

.flow_form .moduls_select_buttons .btn {
  margin: 4px auto;
  width: 100%;
  max-width: 125px;
  display: block;
  padding-left: 0px;
  padding-right: 0px;
}

.flow_form .moduls_select_buttons .btn i {
  margin: 0px;
}

.flow_form .side_moduls_box .select2-search__field {
  width: 100% !important;
}

@media (max-width: 767px) {
  .flow_form .side_moduls_box .form_title a {
    display: table;
    margin: 5px auto;
  }
  .flow_form .select_moduls_box .form-control {
    min-height: 150px;
  }
  .flow_form .select_moduls_box .form-control option {
    padding: 5px 0px;
  }
  .flow_form .moduls_select_buttons .btn {
    width: 100%;
    float: none;
  }
}

@media (max-width: 479px) {
  .col-xss-12 {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .flow_form .moduls_select_buttons {
    padding-top: 0px;
  }
}

/* WorkFlow */

.workflow_listing .dataTables_extended_wrapper {
  margin-top: 0px;
}

.workflow_listing .dataTables_extended_wrapper .table.dataTable {
  margin-top: 0px !important;
}

.workflow_listing .dataTables_wrapper .table-responsive {
  margin-top: 0px;
}

.workflow_listing table tr .workflow_list_title {
  width: auto;
  max-width: 100%;
  min-width: 100%;
  text-align: left;
}

.workflow_listing .icon_title1 .fa-minus:after,
.workflow_listing .icon_title1 .la-minus-square:after {
  left: 29px;
  bottom: -2px;
}

.workflow_listing .workflow_list_title {
  font-weight: 700;
}

.workflow_listing table .producttbl {
  padding: 5px;
  margin: -8px -10px;
  background: #1d4da1;
}

.workflow_listing
  .dataTables_extended_wrapper
  table.new_table_desing
  tbody
  tr
  th,
.workflow_listing
  .dataTables_extended_wrapper
  table.new_table_desing
  tbody
  tr
  td {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.choices {
  &.low {
    .choices__inner {
      color: #fff;
      --vz-bg-opacity: 1;
      background-color: rgba(
        var(--vz-success-rgb),
        var(--vz-bg-opacity)
      ) !important;
      border-color: rgba(
        var(--vz-success-rgb),
        var(--vz-bg-opacity)
      ) !important;
    }
    &:after {
      color: #fff;
    }
  }
  &.medium {
    .choices__inner {
      background-color: #f7b84b;
      color: #fff;
      border-color: #f7b84b;
    }
    &:after {
      color: #fff;
    }
  }
  &.high {
    .choices__inner {
      background-color: rgba(
        var(--vz-danger-rgb),
        var(--vz-bg-opacity)
      ) !important;
      color: #fff;
      border-color: rgba(var(--vz-danger-rgb), var(--vz-bg-opacity)) !important;
      --vz-bg-opacity: 1;
      background-color: rgba(
        var(--vz-danger-rgb),
        var(--vz-bg-opacity)
      ) !important;
    }
    &:after {
      color: #fff;
    }
  }
}
.choices__list--dropdown {
  display: none;
  &.is-active {
    display: block;
  }
}
.images_small {
  img {
    width: 120px;
    height: 100px;
    display: block;
  }
}

.form-label {
  font-size: 16px;
  color: $primary;
}
.text-primary {
  color: $primary;
}
.text-green {
  color: #53a553;
}
.text-orange {
  color: #e37b00;
}
.text-red {
  color: #eb393b;
}

@media screen and (min-width: 1700px) {
  .col-xxxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-xxxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-xxxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-xxxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-xxxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xxxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-xxxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-xxxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-xxxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-xxxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-xxxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-xxxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .order-xxxl-1 {
    order: 1 !important;
  }
  .order-xxxl-2 {
    order: 2 !important;
  }
  .order-xxxl-3 {
    order: 3 !important;
  }
  .order-xxxl-4 {
    order: 4 !important;
  }
  .order-xxxl-5 {
    order: 5 !important;
  }

  .g-xxxl-3,
  .gx-xxxl-3 {
    --bs-gutter-x: 1rem;
  }
  .g-xxxl-3,
  .gy-xxxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxxl-4,
  .gx-xxxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .g-xxxl-4,
  .gy-xxxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxxl-5,
  .gx-xxxl-5 {
    --bs-gutter-x: 3rem;
  }
  .g-xxxl-5,
  .gy-xxxl-5 {
    --bs-gutter-y: 3rem;
  }
}

.error-text {
  color: red;
  font-size: 13px;
}

.MuiOutlinedInput-root{
  textarea{
    padding: 0 !important;
  }
}
.username-login-alert{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  font-size: 20px;
  text-align: center;
  color: red;
  @media (max-width:1400px) {
    font-size: 14px;
  }
}