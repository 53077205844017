//
// _settings.scss
//

.image_thamb_150 {
  max-width: 150px;
  margin-right: 10px;
}
.setting-page {
  .tabpanel {
    padding: 0;
  }
  .setting-card-body{
    padding: 2rem;
  }
}
