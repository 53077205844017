.annoucements-page {
  .filter-choices-input {
    .choices__item {
      background: #ced4da;
      color: #1a2232;
      font-weight: 500;
      border: 0;
    }
  }
  .announcement-list {
    .list-group-item {
      position: relative;
      padding: 1rem 0;
      margin-bottom: 1rem;
      border: 0;
      .title {
        text-align: start;
        position: relative;
        font-size: 18px;
        font-weight: 700;
        padding-bottom: 7px;
        margin-bottom: 15px;
        // margin-top: 20px;
        gap: 10px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-right: 32px;
        span {
          font-size: 14px;
          font-weight: 500;
          position: relative;
          margin-left: 21px;
          &::after {
            content: "";
            content: "";
            position: absolute;
            left: -10px;
            bottom: 0;
            top: 0;
            width: 1px;
            height: 85%;
            margin: auto;
            background-color: #919191;
          }
        }
        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          bottom: 1px;
          background: #e4e8eb;
        }
        .description {
          background-color: #f5f7f8;
          color: #1a2232;
          font-size: 14px;
          border-radius: 30px;
          margin-bottom: 0;
          padding: 7px 15px 7px 20px;
          line-height: 1.2;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      .dropdown-item {
        display: flex;
        align-items: center;
      }
      .more-btn {
        position: absolute;
        right: 0;
        top: 0;
        width: fit-content;
        svg {
          width: 17px;
          height: 17px;
        }
      }
    }
  }
}
