//
// _progress.scss
//

// Progress height small
.progress-sm {
  height: 5px;
}

// Progress height large
.progress-lg {
  height: 12px;
}

// Progress height Extra large
.progress-xl {
  height: 16px;
}

.custom-progess {
  position: relative;

  .progress-icon {
    position: absolute;
    top: -12px;

    .avatar-title {
      background: var(--#{$prefix}card-bg-custom);
    }
  }
}

// animated-progress

.animated-progress {
  position: relative;

  .progress-bar {
    position: relative;
    border-radius: 6px;
    animation: animate-positive 2s;
  }
}

@keyframes animate-positive {
  0% {
    width: 0;
  }
}

// custom-progress

.custom-progress {
  height: 8px;
  border-radius: 30px;
  margin-top: 60px;
  align-items: center;
  .progress-bar {
    position: relative;
    border-radius: 30px;
    height: 15px;
    background-color: #00bd9d;
    &::before {
      content: "";
      position: absolute;
      width: 9px;
      height: 9px;
      background-color: $white;
      border-radius: 7px;
      right: 4px;
      top: 47%;
      transform: translateY(-50%);
    }
  }
}

// progress withlabel
.progress-label {
  overflow: visible;

  .progress-bar {
    position: relative;
    overflow: visible;

    .label {
      position: absolute;
      top: -64px;
      right: -32px;
      background-color: $primary;
      color: $white;
      display: inline-block;
      border-radius: 50px;
      font-size: 20px;
      font-weight: 500;
      padding: 5px 7px;
      span {
        font-size: 12px;
      }
      &:after {
        content: "";
        position: absolute;
        border: 7px solid transparent;
        border-top-color: $primary;
        bottom: -14px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.progress-step-arrow {
  height: 3.25rem;

  .progress-bar {
    position: relative;
    overflow: initial;
    font-size: 0.875rem;
    color: $white;
    &::after {
      content: "";
      position: absolute;
      border: 10px solid transparent;
      bottom: 15px;
      right: -20px;
      z-index: 1;
    }
  }
}

@mixin progress-variant($bg) {
  .progress-bar {
    background-color: $bg;
    &::after {
      border-left-color: $bg;
    }
    &:nth-child(2) {
      background-color: rgba($bg, 0.1) !important;
      color: $bg !important;
      &::after {
        border-left-color: rgba($bg, 0.1);
      }
    }
  }
}

@each $color, $value in $theme-colors {
  .progress-#{$color} {
    @include progress-variant($value);
  }
}
