//
// _authentication.scss
//

.auth-page-wrapper {
  .right-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    padding: 20px;
    @media screen and (max-width: 991.98px) {
      padding: 40px 20px;
    }
  }
  .auth-page-content {
    padding-bottom: $footer-height;
    position: relative;
    z-index: 2;
    width: 100%;
    @media (max-width: 767px) {
      padding-bottom: 0;
    }
  }
  .footer {
    left: 0;
    background-color: transparent;
    color: var(--#{$prefix}body-color);
  }
  .main-title {
    font-size: 45px;
    font-weight: 700;
    color: $primary;
    text-align: center;
    @media (max-width: 1599px) {
      font-size: 40px;
    }
    @media screen and (max-width: 767.98px) {
      font-size: 30px;
    }
    @media screen and (min-width: 992px) and (max-width: 1199.98px) {
      font-size: 30px;
    }
  }
  .main-subtitle {
    font-size: 24px;
    color: $primary;
    text-align: center;
    @media (max-width: 1599px) {
      font-size: 20px;
    }
    @media screen and (max-width: 767.98px) {
      font-size: 18px;
    }
    @media screen and (min-width: 992px) and (max-width: 1199.98px) {
      font-size: 18px;
    }
  }
  .form-container {
    width: 50%;
    @media screen and (max-width: 767.98px) {
      width: 100%;
    }
    @media screen and (max-width: 991.98px) {
      margin: 40px 0;
    }
    @media screen and (min-width: 992px) and (max-width: 1199.98px) {
      width: 70%;
    }
  }
  .login-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // width: 54%;
    margin-top: 60px;
    @media screen and (max-width: 767.98px) {
      flex-direction: column;
      margin-top: 40px;
      width: 100%;
      line-height: 1.8;
    }
    @media screen and (min-width: 768px) and (max-width: 991.98px) {
      width: 70%;
    }
    @media screen and (min-width: 992px) and (max-width: 1199.98px) {
      flex-direction: column;
      margin-top: 40px;
      width: 70%;
      line-height: 1.8;
    }
    a {
      padding: 0 7px;
      display: inline-flex;
      align-items: center;
      color: #757575;
    }
    a {
      svg,
      i {
        margin-right: 5px;
      }
    }
    a,
    svg {
      font-size: 12px;
      color: #757575;
    }
    .terms-line {
      position: relative;
      &::before {
        content: "";
        position: relative;
        left: -7px;
        top: 0;
        bottom: 0;
        width: 1px;
        height: 13px;
        background-color: #878da5;
      }
    }
  }
  .form-control {
    padding: 0.9rem 0.9rem;
    border-radius: 6px;
    &::placeholder {
      color: #757575;
    }
  }
  .form-check-label {
    font-weight: 400;
    color: #757575 !important;
    font-size: 16px;
  }
  .forgot-link {
    color: #757575 !important;
    font-size: 16px;
  }
  .submit-btn {
    font-size: 18px;
    width: 100% !important;
    border-radius: 6px;
  }
}

.auth-one-bg-position {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 380px;

  @media (max-width: 575.98px) {
    height: 280px;
  }
}

.auth-one-bg {
  background-image: url("../../images/auth-one-bg.jpg");
  background-position: center;
  background-size: cover;
  padding: 65px 80px;
  @media (max-width: 767.98px) {
    padding: 40px 40px;
  }
  .bg-overlay {
    background: linear-gradient(to right, darken($primary, 6%), $primary);
    opacity: 0.9;
  }
  .carousel {
    height: 100%;
    @media (max-width: 767.98px) {
      height: auto;
    }
    p {
      font-size: 24px;
      word-break: break-word;
      text-align: start;
      @media (max-width: 1599px) {
        font-size: 20px;
      }
    }
  }
}

.shape {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  pointer-events: none;
  > svg {
    width: 100%;
    height: auto;
    fill: var(--#{$prefix}body-bg);
  }
}

// auth-pass-inputgroup

.auth-pass-inputgroup {
  input[type="text"] + .btn .ri-eye-fill {
    &:before {
      content: "\ecb6";
    }
  }
}

.particles-js-canvas-el {
  position: relative;
}

// signin card title

.signin-other-title {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    right: 0;
    border-top: 1px dashed var(--#{$prefix}border-color);
    top: 10px;
  }

  .title {
    display: inline-block;
    position: relative;
    z-index: 9;
    background-color: var(--#{$prefix}card-bg-custom);
    padding: 2px 16px;
  }
}

// Auth cover

.auth-bg-cover {
  // background: $auth-bg-cover;

  > .bg-overlay {
    background-image: url("../../images/cover-pattern.png");
    background-position: center;
    background-size: cover;
    opacity: 1;
    background-color: transparent;
  }

  .footer {
    color: rgba($white, 0.5);
  }
}

//passowrd validations
#password-contain {
  display: none;

  p {
    padding-left: 13px;

    &.valid {
      color: $success;

      &::before {
        position: relative;
        left: -8px;
        content: "✔";
      }
    }

    &.invalid {
      color: $danger;

      &::before {
        position: relative;
        left: -8px;
        content: "✖";
      }
    }
  }
}
