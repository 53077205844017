.performance-matrix {
  .performance-tab-panel {
    padding: 50px;
    @media (max-width: 768px) {
      padding: 20px;
    }
  }
}
.performance-summary-card {
  border-radius: 50px;
  background-color: #8a98c1;
  font-size: 12px;
  height: 100%;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  .value {
    font-size: 30px;
  }
  .title,
  .subtitle,
  .value {
    color: #fff;
    font-weight: 400;
    margin-bottom: 0;
  }
}
.performance-summary {
  .card-title {
    font-size: 24px;
    color: #1a2232;
    font-weight: bold;
    margin-bottom: 10px;
    svg {
      width: 16px;
      path {
        fill: #ced4da;
      }
    }
  }
  .card-description {
    margin-bottom: 30px;
  }
}

.issue-list {
  li {
    align-items: flex-start;
    padding: 4px 0;
    display: flex;
    align-items: flex-start;
    // flex-wrap: wrap;
    // @media (max-width: 767.98px) {
    //   flex-direction: column;
    // }
  }
  .status-chip {
    min-height: 40px;
    font-size: 14px;
    color: #fff;
    border-radius: 40px;
    min-width: 100px;

    @media (max-width: 767.98px) {
      min-width: 20px;
      border-radius: 16px 0 0 16px;
    }
    .MuiChip-label {
      @media (max-width: 767.98px) {
        display: none;
      }
    }
  }
  // .Mui-expanded {
  //   margin: 0 !important;
  // }
  .issue-accordian {
    border-radius: 40px !important;
    background: #f5f7f8;
    box-shadow: none;
    width: calc(100% - 100px);

    @media (max-width: 767.98px) {
      width: calc(100% - 20px);
      border-radius: 0 16px 16px 0 !important;
    }
    &::before {
      display: none;
    }
    .MuiAccordionSummary-root {
      min-height: 40px;
    }
    .MuiAccordionSummary-content {
      display: flex;
      align-items: center;
      margin: 8px 0;
      gap: 5px;
      @media (max-width: 767.98px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .text-line {
        font-size: 14px;

        &:first-child {
          font-weight: 700;
          width: 60%;
          @media (max-width: 1399.98px) {
            width: 100%;
          }
        }
        &:last-child {
          font-weight: 600;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 37%;
          white-space: nowrap;
          @media (max-width: 1399.98px) {
            width: 100%;
            display: none;
          }
        }
        .MuiChip-root {
          background: #fff;
          margin-left: 5px;
          height: 24px;
          @media (max-width: 767.98px) {
            display: none;
          }
          .MuiChip-label {
            color: #757575;
            font-size: 12px;
            font-weight: 300;
          }
        }
      }
      @media (max-width: 767.98px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  .issue-accordian.Mui-expanded {
    border-radius: 16px !important;
    margin: 0 !important;
    @media (max-width: 767.98px) {
      border-radius: 0 16px 16px 0 !important;
    }
  }
}
.issue-tabpanel {
  padding: 0 !important;
}

.report-summary-loaded {
  margin: 32px 0 21px;
  text-align: center;
  --loaded-line-color: #d5dae0;
  .report-summary-loaded-text {
    position: relative;
  }
  .report-summary-loaded-text:before {
    left: 0;
  }
  .report-summary-loaded-text:after {
    right: 0;
  }
  .report-summary-loaded-text:after,
  .report-summary-loaded-text:before {
    content: "";
    position: absolute;
    top: 2px;
    bottom: 2px;
    width: 4px;
    background: #d5dae0;
  }
  .report-summary-loaded-line {
    position: absolute;
    z-index: 1;
    width: 100%;
    top: calc(50% - 2px);
    height: 4px;
    background: #d5dae0;
  }
  .report-summary-loaded-text h4 {
    display: inline;
    position: relative;
    vertical-align: middle;
    z-index: 2;
    background: #fff;
    font-size: 30px;
    padding: 0 15px;
    margin-bottom: 3px;
    font-weight: 600;
    color: $primary;
  }
}

.matrics-card {
  background-color: #f5f7f8;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100px;
  @media (max-width: 767.98px) {
    height: auto;
    flex-direction: column-reverse;
    border-radius: 16px;
    margin-top: 15px;
  }
  // @media (min-width: 1200px) and (max-width: 1499.98px) {
  //   height: 85px;
  // }
  .left-box {
    height: 100%;
    overflow: hidden;
    border-radius: 50px 0 0 50px;
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    padding-left: 60px;
    padding-right: 10px;
    width: calc(100% - 40%);
    @media (max-width: 767.98px) {
      width: 100%;
      padding: 20px 10px;
      justify-content: center;
      border-radius: 0;
      text-align: center;
      align-items: center;
    }
    @media (min-width: 1200px) and (max-width: 1499.98px) {
      padding-left: 35px;
    }
    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 100%;
      // background: #2ebbf6;
      left: 0;
      @media (max-width: 767.98px) {
        width: 100%;
        height: 4px;
        top: 0;
      }
    }
  }
  .title {
    margin-bottom: 0;
    font-size: 20px;
    gap: 10px;
    font-weight: 400;
    @media (min-width: 1200px) and (max-width: 1499.98px) {
      font-size: 16px;
    }
  }
  .description {
    margin-bottom: 0;
    font-size: 12px;
    @media (min-width: 1200px) and (max-width: 1499.98px) {
      font-size: 11px;
    }
  }
  .right-box {
    // background-color: #fff2dd;
    border-radius: 50px;
    position: relative;
    padding: 15px 25px;
    width: 40%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 767.98px) {
      width: 100%;
      border-radius: 16px 16px 0 0;
      padding: 25px 10px 10px 10px;
    }
    .value {
      margin-bottom: 0;
      font-size: 42px;
      // color: #e37b00;
      @media (max-width: 767.98px) {
        font-size: 30px;
      }
      @media (min-width: 1200px) and (max-width: 1499.98px) {
        font-size: 30px;
      }
    }
  }
  .top-tag {
    position: absolute;
    top: -17px;
    margin: auto;
    color: #fff;
    // background: #f6ab34;
    font-size: 12px;
    width: 75%;
  }
  &.red-card {
    .right-box {
      background-color: #ffebe9;
      color: #ec685d;
    }
    .top-tag {
      background-color: #ec685d;
    }
  }
  &.yellow-card {
    .right-box {
      background-color: #fff2dd;
      color: #f6ab34;
    }
    .top-tag {
      background-color: #f6ab34;
    }
  }
  &.green-card {
    .right-box {
      background-color: #53a5530f;
      color: #53a553;
    }
    .top-tag {
      background-color: #4cc29d;
    }
  }
}

.timing-card {
  background-color: #f5f7f8;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 80px;
  @media (max-width: 767.98px) {
    height: auto;
    flex-direction: column-reverse;
    border-radius: 16px;
    margin-top: 15px;
  }
  .left-box {
    height: 100%;
    overflow: hidden;
    border-radius: 50px 0 0 50px;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 30px;
    @media (max-width: 767.98px) {
      width: 100%;
      padding: 20px 10px;
      justify-content: center;
      border-radius: 0;
    }
    &::before {
      content: "";
      position: absolute;
      width: 13px;
      height: 100%;
      // background: #2ebbf6;
      left: 0;
      @media (max-width: 767.98px) {
        width: 100%;
        height: 4px;
        top: 0;
      }
    }
  }
  .title {
    margin-bottom: 0;
    font-size: 20px;
    gap: 10px;
    font-weight: 400;
    @media (min-width: 1200px) and (max-width: 1600px) {
      font-size: 16px;
    }
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: #ced4da;
      }
    }
  }
  .right-box {
    position: relative;
    width: 30%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    @media (max-width: 767.98px) {
      width: 100%;
      border-radius: 16px 16px 0 0;
      padding: 10px;
    }
    .value {
      margin-bottom: 0;
      font-size: 30px;
      color: $primary;
      font-weight: 600;

      @media (min-width: 1200px) and (max-width: 1499.98px) {
        font-size: 24px;
      }
      @media (min-width: 1500px) and (max-width: 1600px) {
        font-size: 22px;
      }
    }
  }
}
.structure-tab {
  .tab-head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    color: #757575;
    padding: 10px 0;

    @media (max-width: 767.98px) {
      flex-direction: column-reverse;
      align-items: flex-end;
    }
  }
}

.apexcharts-tooltip.apexcharts-theme-light {
  background-color: #ffffff !important;
}